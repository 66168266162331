import React, { Component } from "react";
import ReactLoading from "react-loading";
import Table from "../../components/Table";
import { Scrollbar } from "../../components/Scrollbar";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { HorseService } from "../../services/Services";
import moment from "moment";
import Notifier from "../../components/Notifier";
import ButtonDialog from "../../components/ButtonDialog";
import { Row } from "reactstrap";
import { InputText } from "../../components/Inputs";
import EditHorseDialog from "./EditHorseDialog";
import Button from "../../components/Button";
import { CONST_WIDTH_MOBILE } from "../../helpers/const";

class Horse extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      itemEditing: {},
      popUpEditOpen: false,
      loading: false,
    };
    this.isMobile = window.innerWidth < CONST_WIDTH_MOBILE;
    this.refreshHorses = this.refreshHorses.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshHorses();
  }

  refreshHorses = () => {
    this.setState({ loading: true });
    HorseService.getAll().then((items) => {
      if (this._isMounted) {
        this.setState({
          items: items.map((x) => ({
            ...x,
            usernames: x.horseUser.map((hu) => hu.user?.name).join(", "),
          })),
          loading: false,
        });
      }
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onItemDeleted = (item) => {
    HorseService.delete(item.id)
      .then(() => {
        this.refreshHorses();
        Notifier.showNotification("success", `Le cheval à bien été supprimée`);
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de la suppression`);
      });
  };

  handleClose = () => {
    this.setState({ popUpEditOpen: false });
  };

  render() {
    const {
      state: { items, itemEditing, popUpEditOpen, loading },
    } = this;

    return (
      <div className="d-flex flex-column flex-grow-1 p-2">
        {loading && (
          <div className="app-loading-spinner">
            <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
          </div>
        )}

        <EditHorseDialog
          data={itemEditing}
          open={popUpEditOpen}
          handleUpdate={this.refreshHorses}
          handleClose={() => this.setState({ popUpEditOpen: false })}
        />

        <div style={{ position: "fixed", bottom: "15px", right: "15px", zIndex: 1 }}>
          <Button
            onClick={() => this.setState({ popUpEditOpen: true, itemEditing: {} })}
            style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)", borderRadius: "2rem" }}
            color="primary"
          >
            Ajouter un cheval
          </Button>
        </div>

        <div className="shadowed p-6" style={{ backgroundColor: "#fff" }}>
          <Table
            columns={[
              { title: "Nom", field: "name" },
              { title: "Commentaire", field: "comment" },
              { title: "Date de naissance", field: "birthday", dateFormat: true },
              { title: "Proprietaire", field: "usernames" },
            ]}
            data={items}
            handleEdit={(item) => this.setState({ itemEditing: item, popUpEditOpen: true })}
            handleDelete={(item) => {
              this.onItemDeleted(item);
            }}
            options={{
              search: true,
              noPadding: false,
              selectedRowColor: "var(--gray)",
            }}
            style={{ backgroundColor: "#fff", marginBottom: "80px" }}
          >
            <colgroup>
              <col span="1" />
              <col span="1" />
              <col span="1" /> 
              <col span="1" />
              <col span="1" style={{ width: "50px" }} />
            </colgroup>
          </Table>
        </div>
      </div>
    );
  }
}

export { Horse };
