import GlobalService from "./_GlobalService";

class RoleService extends GlobalService {

    constructor() {
        super("roles");
    }
    

};

export default new RoleService();