import React from "react";
import { Topbar as AppTopbar, TopbarGroup } from "../../components/Topbar";
import { Link } from "react-router-dom";
import { FaUsers, FaHome, FaRegCalendarAlt, FaUserLock, FaCalendarCheck, FaHorse, FaFileExport, FaLayerGroup } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { GiVendingMachine } from "react-icons/gi";
import { Tooltip } from "@material-ui/core";

export const Topbar = () => {
  return (
    <AppTopbar className="shadowed" style={{ border: "none", borderRadius: 0 }}>
      <TopbarGroup title="Accueil">
        <Tooltip title="Accueil" arrow>
          <Link className="app-topbar-item-icon" to="/dashboard">
            <FaHome />
          </Link>
        </Tooltip>
        <Tooltip title="Calendrier" arrow>
          <Link className="app-topbar-item-icon" to="/calendar">
            <FaRegCalendarAlt />
          </Link>
        </Tooltip>
        <Tooltip title="Exports" arrow>
          <Link className="app-topbar-item-icon" to="/export">
            <FaFileExport />
          </Link>
        </Tooltip>
      </TopbarGroup>
      <TopbarGroup title="Listes">
        <Tooltip title="Clients" arrow>
          <Link className="app-topbar-item-icon" to="/users">
            <FaUsers />
          </Link>
        </Tooltip>
        <Tooltip title="Préstations" arrow>
          <Link className="app-topbar-item-icon" to="/prestations">
            <FaCalendarCheck />
          </Link>
        </Tooltip>
        <Tooltip title="Chevaux" arrow>
          <Link className="app-topbar-item-icon" to="/horses">
            <FaHorse />
          </Link>
        </Tooltip>
        <Tooltip title="Machine à café" arrow>
          <Link className="app-topbar-item-icon" to="/coffee">
            <GiVendingMachine />
          </Link>
        </Tooltip>
      </TopbarGroup>
      <TopbarGroup title="Paramétrage">
        <Tooltip title="Type de préstations" arrow>
          <Link className="app-topbar-item-icon" to="/prestationstypes">
            <MdLibraryBooks />
          </Link>
        </Tooltip>
        {/* <Tooltip title="Rôles" arrow>
          <Link className="app-topbar-item-icon" to="/roles">
            <FaUserLock />
          </Link>
        </Tooltip> */}
        <Tooltip title="Groupes de prestation" arrow>
          <Link className="app-topbar-item-icon" to="/groups">
            <FaLayerGroup />
          </Link>
        </Tooltip>
      </TopbarGroup>
    </AppTopbar>
  );
};
