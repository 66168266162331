import React, { useState, Component } from "react"
import ReactLoading from "react-loading"
import Table from "../../components/Table"
import { Scrollbar } from "../../components/Scrollbar"
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Select, MenuItem } from "@material-ui/core"
import { PrestationService, UserService } from "../../services/Services"
import EditPrestationPanel from "./EditPrestationPanel"
import CreatePrestation from "./CreatePrestation"
import moment from "moment"
import Notifier from "../../components/Notifier"
import { CONST_WIDTH_MOBILE } from "../../helpers/const"
import CustomDialogTitle from "../../components/Dialog/dialog-title"
import Button from "../../components/Button"
import { useEffect } from "react"
import PrestationGroupService from "../../services/PrestationGroupService"
import { useWindowSize } from "../../hooks"

function ChangePrestationGroup({ prestations, groups, handleSuccess }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [group, setGroup] = useState()

  const movePrestations = () => {
    setLoading(true)
    PrestationService.move({ group, prestations })
      .then(() => {
        setOpen(false)
        setLoading(false)
        if (handleSuccess) handleSuccess()
        Notifier.showNotification("success", `Les préstations ont bien été déplacées`)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        Notifier.showNotification("error", `Erreur lors du déplacement`)
      })
  }

  return (
    <div style={{ position: "fixed", bottom: "75px", right: "15px", zIndex: 1 }}>
      <Dialog maxWidth={false} onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent className="d-flex flex-column">
          {loading && (
            <div className="app-loading-spinner">
              <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
            </div>
          )}
          <div>Vers quel groupe ?</div>
          <div className="mt-4">
            <Select label="Sélectionner un groupe" value={group} onChange={(e) => setGroup(e.target.value)}>
              {groups &&
                groups.map((g, i) => (
                  <MenuItem key={i} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="d-flex mt-4">
            <Button size="sm" disabled={loading} onClick={() => setOpen(false)} color="danger">
              {loading ? <ReactLoading type="bubbles" height={26} width={26} /> : <>Annuler</>}
            </Button>
            <Button size="sm" disabled={loading} onClick={movePrestations}>
              {loading ? <ReactLoading type="bubbles" height={26} width={26} /> : <>Déplacer</>}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {prestations && prestations.length > 0 && (
        <Button
          onClick={() => setOpen(true)}
          style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)", borderRadius: "2rem" }}
          color="primary"
        >
          Changer de groupe
        </Button>
      )}
    </div>
  )
}

function Prestation({}) {
  const [items, setItems] = useState([])
  const [groups, setGroups] = useState([])
  const [itemEditing, setItemEditing] = useState({})
  const [popUpEditOpen, setPopUpEditOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedItems, setSelectedItems] = useState([])
  const { isMobile } = useWindowSize()

  var tableColumns = [
    { title: "Type", field: "template" },
    { title: "Commentaire", field: "comment" },
    { title: "Prix", field: "price" },
  ]

  if (!isMobile) {
    tableColumns = [
      ...tableColumns,
      { title: "Début", field: "dateStart", dateFormat: true },
      { title: "Fin", field: "dateEnd", dateFormat: true },
    ]
  }

  useEffect(() => {
    refreshPrestations()

    PrestationGroupService.getAll()
      .then(setGroups)
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la récupération des groupes`)
      })
  }, [])

  const refreshPrestations = () => {
    setLoading(true)
    PrestationService.getAll()
      .then((res) => {
        setItems(res)
        setLoading(false)
        setPopUpEditOpen(false)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la récupération des données`)
        setLoading(false)
      })
  }

  const onItemEdit = (item) => {
    setItemEditing(item)
    setPopUpEditOpen(true)
  }

  const onItemCreate = () => {
    setItemEditing({ id: -1 })
    setPopUpEditOpen(true)
  }

  const onItemDeleted = (item) => {
    PrestationService.delete(item.id)
      .then(() => {
        refreshPrestations()
        Notifier.showNotification("success", `La préstation à bien été supprimée`)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la suppression`)
      })
  }

  const handleClose = () => {
    setPopUpEditOpen(false)
  }

  return (
    <div className="d-flex flex-column flex-grow-1 p-2">
      {loading && (
        <div className="app-loading-spinner">
          <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
        </div>
      )}

      <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={popUpEditOpen}>
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Édition préstation
        </CustomDialogTitle>
        <DialogContent dividers className="d-flex flex-column p-3">
          <EditPrestationPanel data={itemEditing} handleChange={refreshPrestations} />
        </DialogContent>
      </Dialog>
      <CreatePrestation handleCreate={refreshPrestations} />
      <ChangePrestationGroup
        groups={groups}
        prestations={selectedItems}
        handleSuccess={() => {
          refreshPrestations()
          setSelectedItems([])
        }}
      />
      <div className="shadowed w-100 h-100 p-6" style={{ backgroundColor: "#fff", overflowX: "auto" }}>
        <Table
          columns={tableColumns}
          data={items.sort((a, b) => {
            if (moment(a.dateStart).isBefore(b.dateStart)) return 1
            if (moment(a.dateStart).isAfter(b.dateStart)) return -1
            return 0
          })}
          handleEdit={onItemEdit}
          handleDelete={onItemDeleted}
          handleSelection={setSelectedItems}
          options={{
            search: true,
            noPadding: false,
          }}
          style={{ backgroundColor: "#fff", marginBottom: "80px" }}
          groupBy="group"
        >
          <colgroup>
            <col span="1" style={{ width: "40px" }} />
            <col span="1" style={{ minWidth: "100px" }} />
            <col span="1" style={{ minWidth: "100px" }} />
            <col span="1" style={{ width: "40px" }} />
            {!isMobile && (
              <>
                <col span="1" style={{ minWidth: "60px" }} />
                <col span="1" style={{ minWidth: "60px" }} />
              </>
            )}

            <col span="1" style={{ width: "40px" }} />
          </colgroup>
        </Table>
      </div>
    </div>
  )
}

export { Prestation, EditPrestationPanel, CreatePrestation }
