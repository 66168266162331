import React from "react";
import Card from "../../components/Card";
import ReactLoading from "react-loading";
import { Line, Pie } from "react-chartjs-2";
import moment from "moment";
import "moment/locale/fr";
import { isLoggedIn } from "../../helpers/auth";
import Tabs from "../../components/Tabs";
import Button from "../../components/Button";
import { customFetch } from "../../helpers/fetch";
import { CONST_URI_API } from "../../helpers/const";
import Notifier from "../../components/Notifier";
import { downloadFile } from "../../helpers/helpers";
import { Scrollbar } from "../../components/Scrollbar";

import "./style.css";

function CustomLegend({ title, color, children }) {
  return (
    <div>
      <div className="d-inline-flex">
        <div className="m-auto" style={{ borderRadius: "50%", width: 10, height: 10, backgroundColor: color }}></div>
        <span className="ml-2">{title}</span>
      </div>
      <div className="text-center font-weight-bold">{children}</div>
    </div>
  );
}

var loadingSpinner = "40px";
function CardContent({ isLoading, children }) {
  if (isLoading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <ReactLoading color="#303131" type="spinningBubbles" height={loadingSpinner} width={loadingSpinner} />
      </div>
    );
  else return <>{children}</>;
}

const defaultLineChartOptions = {
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const months = new Array(12)
  .fill()
  .map((x, i) => moment().subtract(i, "months").format("MMMM"))
  .reverse();
const weeks = new Array(24)
  .fill()
  .map((x, i) => {
    let date = moment().subtract(i, "weeks");
    return date.format("DD/MM") + " - " + date.add(7, "days").format("DD/MM");
  })
  .reverse();

export default function Dashboard() {
  const [stats, setStats] = React.useState({
    genders: [],
  });
  const [genders, setGenders] = React.useState([]);
  const [horses, setHorses] = React.useState({
    isLoading: false,
    total: 0,
    genders: [],
    types: [],
  });
  const [coffeeTransactions, setCoffeeTransactions] = React.useState({
    isLoading: false,
    total: 0,
  });
  const [clientsEach, setClientsEach] = React.useState({
    isLoading: false,
    total: 0,
  });

  const chartPrestationsEachMonth = {
    labels: months,
    datasets: [
      {
        borderColor: ["#545cd8"],
        data: months.map((m) => {
          let dataFound = stats.prestationsEachMonth?.data.find((p) => p.key == m);
          if (dataFound) return dataFound.value;
          else return 0;
        }),
      },
    ],
    options: defaultLineChartOptions,
  };
  const chartClientsEach = {
    labels: clientsEach.period === "week" ? weeks : months,
    datasets: [
      {
        borderColor: ["#545cd8"],
        data: (clientsEach.period === "week" ? weeks : months).map((m) => {
          let dataFound = clientsEach.data?.find((p) => p.key == m);
          if (dataFound) return dataFound.value;
          else return 0;
        }),
      },
    ],
    options: defaultLineChartOptions,
  };

  const chartGender = {
    labels: ["Hommes", "Femmes"],
    datasets: [
      {
        data: [stats.genders[0], stats.genders[1]],
        backgroundColor: ["#36a2eb", "#ff6385"],
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "left",
        labels: {
          boxWidth: 16,
        },
      },
    },
  };

  const chartGendersHorses = {
    labels: ["Cheval", "Jument"],
    datasets: [
      {
        data: [horses.genders[0], horses.genders[1]],
        backgroundColor: ["#36a2eb", "#ff6385"],
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
  };
  const chartTypesHorses = {
    labels: ["Demi-pension", "Pension", "Au club", "Au pair"],
    datasets: [
      {
        data: [horses.types.dp, horses.types.pension, horses.types.proprio, horses.types.atPair],
        backgroundColor: ["#36a2eb", "#ff6385", "#a2eb36", "#eb7f36"],
      },
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
  };

  React.useEffect(() => {
    setStats({ ...stats, isLoading: true });
    customFetch("GET", `${CONST_URI_API}stats`)
      .then((data) => {
        setStats({ ...data, isLoading: false });
      })
      .catch((e) => {
        console.error(e);
        setStats({ ...stats, isLoading: true });
      });

    setHorses({ ...horses, isLoading: true });
    customFetch("GET", `${CONST_URI_API}stats/horses`)
      .then((data) => {
        setHorses({ ...data, isLoading: false });
      })
      .catch((e) => {
        console.error(e);
        setHorses({ ...horses, isLoading: false });
      });

    setCoffeeTransactions({ ...coffeeTransactions, isLoading: true });
    customFetch("GET", `${CONST_URI_API}stats/coffee`)
      .then((data) => {
        setCoffeeTransactions({ total: data, isLoading: false });
      })
      .catch((e) => {
        console.error(e);
      });

    updateClientsEach("month");
  }, []);

  const updateClientsEach = (period) => {
    if (period != clientsEach.period) {
      setClientsEach({ ...clientsEach, isLoading: true, period: period });
      customFetch("GET", `${CONST_URI_API}stats/clientsEach?period=${period}`)
        .then((data) => {
          setClientsEach({ ...data, isLoading: false });
        })
        .catch((e) => {
          console.error(e);
          setClientsEach({ ...clientsEach, isLoading: false });
        });
    }
  };

  return (
    <Scrollbar>
      <div className="container-fluid pt-3">
        <div className="date row ml-5">{moment().format("dddd Do MMMM")}</div>
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt">
              <Card title="Derniers utilisateurs inscris">
                <CardContent isLoading={stats.isLoading}>{stats.lastUsers && stats.lastUsers.map((u) => <div>{u.name}</div>)}</CardContent>
              </Card>
            </div>

            <div className="col-md-3 mt">
              <Card title="Prochains anniversaires">
                <CardContent isLoading={stats.isLoading}>
                  {stats.usersNextBirthdays &&
                    stats.usersNextBirthdays.map((u) => (
                      <div>
                        {u.birthday} - {u.name}
                      </div>
                    ))}
                </CardContent>
              </Card>
            </div>

            <div className="col-md-3 mt">
              <Card title="Gain de la machine à café">
                <CardContent isLoading={coffeeTransactions.isLoading}>
                  <div className={`${(coffeeTransactions.total >= 0 ? "success" :"danger")} number`}>
                    {coffeeTransactions.total > 0 ? "+" : ""}{coffeeTransactions.total} €
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt">
              <Card title={`Utilisateurs (total : ${stats.usersCount})`}>
                <CardContent isLoading={stats.isLoading}>
                  <div className="d-flex">
                    <div style={{ height: "160px", width: "70%" }}>
                      <Pie data={chartGender} options={chartGender.options}></Pie>
                    </div>
                    <div className="d-flex">
                      <div className="m-auto">
                        <CustomLegend title="Femmes" color="#ff6385">
                          {stats.genders[1]}
                        </CustomLegend>

                        <CustomLegend title="Hommes" color="#36a2eb">
                          {stats.genders[0]}
                        </CustomLegend>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-4 mt">
              <Card title={`Chevaux (total : ${horses.total})`}>
                <CardContent isLoading={horses.isLoading}>
                  <div className="d-flex">
                    <div style={{ height: "160px", width: "70%" }}>
                      <Pie data={chartGendersHorses} options={chartGendersHorses.options}></Pie>
                    </div>
                    <div className="d-flex">
                      <div className="m-auto">
                        <CustomLegend title="Juments" color="#ff6385">
                          {horses.genders[1]}
                        </CustomLegend>

                        <CustomLegend title="Chevaux" color="#36a2eb">
                          {horses.genders[0]}
                        </CustomLegend>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-4 mt">
              <Card title={`Type de chevaux`}>
                <CardContent isLoading={horses.isLoading}>
                  <div className="d-flex">
                    <div style={{ height: "160px", width: "70%" }}>
                      <Pie data={chartTypesHorses} options={chartTypesHorses.options}></Pie>
                    </div>
                    <div className="d-flex">
                      <div className="m-auto">
                        <CustomLegend title="DP">{horses.types.dp}</CustomLegend>
                        <CustomLegend title="Au club">{horses.types.proprio}</CustomLegend>
                        <CustomLegend title="Pension">{horses.types.pension}</CustomLegend>
                        <CustomLegend title="Au pair">{horses.types.atPair}</CustomLegend>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mt">
              <Card title={`Nombre de clients (total : ${clientsEach.total})`}>
                <CardContent isLoading={clientsEach.isLoading}>
                  <div className="d-flex justify-content-end mt-n2">
                    <Button style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} onClick={() => updateClientsEach("week")}>
                      SEMAINE
                    </Button>
                    <div style={{ margin: "-0.45rem" }}></div>
                    <Button style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={() => updateClientsEach("month")}>
                      MOIS
                    </Button>
                  </div>
                  <Line data={chartClientsEach} height={250} options={chartClientsEach.options}></Line>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-6 mt">
              <Card title={`Prestations par mois (total : ${stats.prestationsEachMonth?.total})`}>
                <CardContent isLoading={stats.isLoading}>
                  <Line data={chartPrestationsEachMonth} height={250} options={chartPrestationsEachMonth.options}></Line>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
    </Scrollbar>
  );
}
