import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={[/[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/]}
      showMask
      autoComplete="off"
    />
  );
}

PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export { PhoneMask };
