import React from "react";
import EditHorseDialog from "../Horse/EditHorseDialog";
import Table from "../../components/Table";
import Panel from "../../components/Panel";
import { Col } from "reactstrap";
import { InputText, InputDate, InputSelect } from "../../components/Inputs";
import { UserService } from "../../services/Services";
import Button from "../../components/Button";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Notifier from "../../components/Notifier";
import { equals } from "../../helpers/helpers";
import { jsDateToString } from "../../helpers/date";
import ReactLoading from "react-loading";

export default function UserInformation(props) {
  const { user: baseUser, handleUpdate } = props;

  const [user, setUser] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [horseEditing, setHorseEditing] = React.useState();
  const [popUpHorseOpen, setPopUpHorseOpen] = React.useState(false);

  React.useEffect(() => {
    if (baseUser && baseUser.id) {
      setUser(baseUser);
    } else {
      setUser({});
    }

    setPopUpHorseOpen(false);
    setLoading(false);
  }, [baseUser]);

  const onInputChange = (event, value) => {
    setUser({ ...user, [event.target.name]: value });
  };

  const onShowHorsePopUp = (horseUser) => {
    console.log(horseUser);
    setHorseEditing({
      ...horseUser.horse,
      horseUser: [...horseUser.horse.horseUser, horseUser],
    });
    setPopUpHorseOpen(true);
  };

  const onSaveUserClicked = () => {
    setLoading(true);
    UserService.update(user)
      .then((success) => {
        handleUpdate();
        Notifier.showNotification("success", "Utilisateur sauvegardé");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        Notifier.showNotification("error", "Impossible de sauver l'utilisateur");
      });
  };

  const onCancelUserClicked = () => {
    setUser(baseUser);
  };
  return (
    <div className="d-flex flex-column mt-2">
      {!equals(user, baseUser) && (
        <div className="mb-3">
          <div className="d-inline-flex">
            <Button disabled={loading} size="sm" type="submit" onClick={onSaveUserClicked} color="primary">
              {loading ? <ReactLoading type="bubbles" height={20} width={20} /> : <SaveOutlinedIcon />}
            </Button>
            <Button disabled={loading} size="sm" onClick={onCancelUserClicked} color="danger">
              <CancelOutlinedIcon />
            </Button>
          </div>
        </div>
      )}
      <Panel label="Information personnelles" className="mb-3 d-flex flex-column">
        <div className="clearfix">
          <Col xs="auto" className="panel-field">
            <InputText name="firstName" title="Prénom" value={user.firstName} handleChange={onInputChange} />
          </Col>
          <Col xs="auto" className="panel-field">
            <InputText name="lastName" title="Nom" value={user.lastName} handleChange={onInputChange} />
          </Col>

          <Col xs="auto" className="panel-field">
            <InputSelect
              name="gender"
              title="Genre"
              value={user.gender}
              values={[
                { title: "Femme", value: "F" },
                { title: "Homme", value: "H" },
              ]}
              handleChange={onInputChange}
            />
          </Col>
        </div>
        <div className="clearfix">
          <Col xs="auto" className="panel-field">
            <InputText name="email" title="Email" value={user.email} handleChange={onInputChange} />
          </Col>
          <Col xs="auto" className="panel-field">
            <InputText name="phone" title="Téléphone" type="phone" value={user.phone} handleChange={onInputChange} />
          </Col>
          <Col xs="auto" className="panel-field">
            <InputDate
              name="birthday"
              label="Date de naissance"
              dateFormat="yyyy-MM-dd"
              value={user.birthday}
              handleChange={(e, value) => setUser({ ...user, birthday: jsDateToString(value) })}
            />
          </Col>

          <Col xs="auto" className="panel-field">
            <InputText name="licence" title="Licence" value={user.licence} handleChange={onInputChange} />
          </Col>

          <Col xs="auto" className="panel-field">
            <InputText name="credits" title="Crédits" type="number" value={user.credits} handleChange={onInputChange} />
          </Col>
        </div>
        <div className="clearfix">
          <Col xs="auto" className="panel-field">
            <InputText
              style={{ width: "300px" }}
              name="address"
              title="Adresse"
              multiline
              rows="3"
              value={user.address}
              handleChange={onInputChange}
            />
          </Col>
        </div>
      </Panel>
      <Panel label="Personne à contacter" className="mb-3">
        <div className="clearfix">
          <Col xs="auto" className="panel-field">
            <InputText name="nameToContact" title="Nom" value={user.nameToContact} handleChange={onInputChange} />
          </Col>
          <Col xs="auto" className="panel-field">
            <InputText name="phoneToContact" title="Téléphone" type="phone" value={user.phoneToContact} handleChange={onInputChange} />
          </Col>
        </div>
        <div className="clearfix">
          <Col xs="auto" className="panel-field">
            <InputText name="nameToContact2" title="Nom" value={user.nameToContact2} handleChange={onInputChange} />
          </Col>
          <Col xs="auto" className="panel-field">
            <InputText name="phoneToContact2" title="Téléphone" type="phone" value={user.phoneToContact2} handleChange={onInputChange} />
          </Col>
        </div>
      </Panel>
      <Panel label="Chevaux" className="mb-3">
        <div className="clearfix">
          <Col xs="auto" className="panel-field">
            <EditHorseDialog
              data={horseEditing}
              open={popUpHorseOpen}
              handleUpdate={() => handleUpdate()}
              handleClose={() => setPopUpHorseOpen(false)}
            />

            <Table
              columns={[
                { title: "Nom", field: "horse.name" },
                { title: "Commentaire", field: "horse.comment" },
                { title: "SIF", field: "horse.number" },
                { title: "Date d'achat", field: "horse.date", dateFormat: true },
              ]}
              data={user.horseUser}
              className="small"
              handleEdit={(item) => onShowHorsePopUp(item)}
              options={{
                noSelection: true,
                headerStyle: {
                  backgroundColor: "var(--gray)",
                },
              }}
            />
          </Col>
        </div>
      </Panel>
      <Panel label="Commentaires" className="mb-3">
        <InputText style={{ width: "100%" }} name="comment" multiline rows="10" value={user.comment} handleChange={onInputChange} />
      </Panel>
    </div>
  );
}
