import Axios from "axios";
import GlobalService from "./_GlobalService";
import { customFetch } from "../helpers/fetch";

class FileService extends GlobalService {
  constructor() {
    super("files");
  }

  create = (item) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("name", item.name);
      formData.append("file", item);
      formData.append("type", item.type);
      formData.append("userId", item.userId);

      customFetch("POST", `${this.baseUrl}`, null, { body: formData, noContentType: true })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
}

export default new FileService();
