import React, { useEffect, useState } from "react"
import { Row, Col, Container } from "reactstrap"
import { PrestationTypeService, PrestationService, UserService } from "../../services/Services"
import { InputText, InputSelect, InputSelectCheckbox, InputDate } from "../../components/Inputs"
import Select from "react-select"
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import Notifier from "../../components/Notifier"
import { ConditionalScrollBar, Scrollbar } from "../../components/Scrollbar"
import Panel from "../../components/Panel"
import Button from "../../components/Button"
import ReactLoading from "react-loading"
import { SketchPicker } from "react-color"
import { RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent } from "@material-ui/core"
import { Calendar as RBCalendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
//import "moment/locale/fr";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop"
import "react-big-calendar/lib/addons/dragAndDrop/styles.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { length, notNull, equals } from "../../helpers/helpers"
import { getMomentDate, jsDateToString, momentDateToString, stringDateToFormattedDate } from "../../helpers/date"
import { FaTrash } from "react-icons/fa"
import { CONST_CALENDAR_MESSAGES, CONST_DAYS_WEEK, PrestationTypes } from "../../helpers/const"
import CustomDialogTitle from "../../components/Dialog/dialog-title"
import SmallCalendar from "../../components/Calendar/SmallCalendar"

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(RBCalendar)

export default function EditPrestationPanel(props) {
  const { data, className, children, handleChange, fixed, ...other } = props

  const [prestation, setPrestation] = React.useState()
  const [prestationsTemplates, setPrestationsTemplates] = React.useState([])
  const [originalPrestation, setOriginalPrestation] = React.useState()
  const [users, setUsers] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [loadingCreate, setLoadingCreate] = React.useState(false)
  const [showColorPicker, setShowColorPicker] = React.useState(false)
  const [showPopupGenerateDate, setShowPopupGenerateDate] = React.useState(false)
  const [showGenerateDateSelect, setShowGenerateDateSelect] = React.useState(false)
  const [dayGenerateDate, setDayGenerateDate] = React.useState(0)
  const [prestationTypeSelected, setPrestationTypeSelected] = React.useState()

  const [showUserInfo, setShowUserInfo] = useState(false)
  const [userInfo, setUserInfo] = useState(null)

  // force to recall when props change
  useEffect(() => {
    setLoading(true)
    Promise.all([PrestationTypeService.getAll(), UserService.getAllMinimized()])
      .then((res) => {
        let prestationsTemplate = res[0]
        let users = res[1]

        // PRESTA
        let currPrestation = {
          start: jsDateToString(moment().toDate()),
          end: jsDateToString(moment().add(1, "hours").toDate()),
          visibleCalendar: 0,
          color: "#8989FF",
          date: [],
          users: [],
          type: 0,
        }
        if (prestationsTemplate && prestationsTemplate.length > 0) {
          var allPresta = prestationsTemplate.map((presta) => ({
            ...presta,
            title: presta.name,
            value: presta.name,
          }))
          currPrestation.price = allPresta[0].price
          setPrestationsTemplates(allPresta)
          setPrestationTypeSelected(allPresta[0])
        }
        setPrestation(currPrestation)

        // USERS
        setUsers(users.map((user) => ({ ...user, value: user.id, label: user.name })))
        if (data.id === -1) setLoading(false)
        else {
          PrestationService.find(data.id)
            .then((presta) => {
              console.log(presta)
              let momentStart = moment(presta.start)
              let momentEnd = moment(presta.end)
              setPrestation({
                ...presta,
                start:
                  presta.type == PrestationTypes.DATES_RECURRING_FOR_ALL && momentStart.isValid()
                    ? momentStart.format("HH:mm")
                    : presta.start,
                end:
                  presta.type == PrestationTypes.DATES_RECURRING_FOR_ALL && momentEnd.isValid()
                    ? momentEnd.format("HH:mm")
                    : presta.end,
              })
              setOriginalPrestation(JSON.parse(JSON.stringify(presta)))
              setLoading(false)
            })
            .catch((e) => console.error(e))
        }
      })
      .catch((e) => console.error(e))
  }, [data])

  useEffect(() => {
    var items = document.getElementsByClassName("select__multi-value__label")

    for (var i = 0; i < items.length; i++) {
      items[i].onclick = function () {
        var uFound = users.find((x) => x.label.trim() === this.outerText.trim())

        setUserInfo(null)
        setShowUserInfo(true)
        UserService.find(uFound.id)
          .then((userFound) => {
            setUserInfo(userFound)
          })
          .catch((e) => {
            console.error(e)
            alert(e)
            setShowUserInfo(false)
          })
      }
    }
  }, [prestation?.prestationUser])

  const onInputChange = (event, value) => {
    setPrestation({ ...prestation, [event.target.name]: value })
  }

  const onTemplateChange = (e) => {
    const typeFound = prestationsTemplates.find((presta) => presta.value === e.target.value)
    if (typeFound) {
      setPrestation({
        ...prestation,
        template: e.target.value,
        type: typeFound.type,
        price: typeFound.price ? typeFound.price : prestation.price,
        comment: typeFound.comment ? typeFound.comment : prestation.comment,
        color: typeFound.color ? typeFound.color : prestation.color,
        max: typeFound.max ? typeFound.max : prestation.max,
        visibleCalendar: typeFound.visibleCalendar,
      })
      setPrestationTypeSelected(typeFound)
      if (typeFound.type == PrestationTypes.DATES_RECURRING_FOR_ALL) setShowPopupGenerateDate(true)
    }
  }

  const getFormattedEventsForPresta = () => {
    return (
      prestation.datePrestation &&
      prestation.datePrestation.map((date) => ({
        start: moment(date).startOf("day"),
        end: moment(date).endOf("day"),
        allDay: true,
        title: " ",
      }))
    )
  }

  const updatePresta = () => {
    if (
      prestation.max > 0 &&
      prestation.prestationUser &&
      prestation.prestationUser.length > prestation.max &&
      !window.confirm(
        `Attention, il y a ${prestation.prestationUser.length} clients saisis, pour un maximum de ${prestation.max} clients max pour cette prestation. Es-tu sûre de vouloir sauvegarder ?`
      )
    )
      return

    setLoadingCreate(true)
    PrestationService.update(prestation)
      .then(() => {
        setOriginalPrestation(prestation)
        Notifier.showNotification("success", `Préstation sauvegardée`)
        if (handleChange) handleChange()
        setLoadingCreate(false)
        localStorage.setItem("last_prestation", prestation.type)
      })
      .catch((e) => {
        console.error(e)
        setLoadingCreate(false)
        Notifier.showNotification("error", `Erreur lors de l'update : ${e}`)
      })
  }

  const addPresta = () => {
    if (
      prestation.max > 0 &&
      prestation.prestationUser &&
      prestation.prestationUser.length > prestation.max &&
      !window.confirm(
        `Attention, il y a ${prestation.prestationUser.length} clients saisis, pour un maximum de ${prestation.max} clients max pour cette prestation. Es-tu sûre de vouloir sauvegarder ?`
      )
    )
      return

    setLoadingCreate(true)
    PrestationService.create(prestation)
      .then(() => {
        Notifier.showNotification("success", `Préstation ajoutée`)
        if (handleChange) handleChange()
        localStorage.setItem("last_prestation", prestation.type)
        setLoadingCreate(false)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la sauvegarde : ${e}`)
        setLoadingCreate(false)
      })
  }

  const cancelPresta = () => {
    setPrestation(originalPrestation)
  }

  const onDeletePresta = () => {
    setLoading(true)
    PrestationService.delete(prestation.id)
      .then(() => {
        setLoading(false)
        Notifier.showNotification("success", `Préstation supprimée`)
        if (handleChange) handleChange()
      })
      .catch((e) => {
        setLoading(false)
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la suppression : ${e}`)
      })
  }

  const onUsersChange = (newUsers) => {
    let newUsersFound = []
    if (length(newUsers) > 0) {
      newUsers.forEach((user) => {
        newUsersFound.push({
          userId: user.value,
          prestationId: prestation.id,
        })
      })
    }

    setPrestation({
      ...prestation,
      prestationUser: newUsersFound,
    })
  }

  const onSlotSelect = (events) => {
    console.log(events)
    setPrestation({ ...prestation, datePrestation: events.map((e) => momentDateToString(e.start)) })
  }

  const onDateTypeChange = (e, value) => {
    const type = parseInt(value)

    setPrestation({
      ...prestation,
      type: type,
    })

    /*
    let momentStart, momentEnd;
    if (type == PrestationTypes.DATES_RECURRING_FOR_ALL) {
      momentStart = moment(prestation.start, "HH:mm");
      momentEnd = moment(prestation.end, "HH:mm");
      if (prestation.start?.) {
        momentStart = moment(prestation.start);
        momentEnd = moment(prestation.end);
      }
      momentStart = momentStart.format("HH:mm");
      momentEnd = momentEnd.format("HH:mm");
    } else {
      momentStart = originalPrestation ? originalPrestation.start : moment();
      momentEnd = originalPrestation ? originalPrestation.end : moment();
    }

    setPrestation({
      ...prestation,
      start: momentStart,
      end: momentEnd,
      type: type,
    });*/
  }

  const onClosePopupGenerateDate = () => {
    setShowPopupGenerateDate(false)
    setShowGenerateDateSelect(false)
    setDayGenerateDate(0)
  }

  const generateDates = () => {
    var newEvents = []
    if (prestationTypeSelected.type == PrestationTypes.DATES_RECURRING_FOR_ALL) {
      prestationTypeSelected.datePrestationType.forEach((d) => {
        var date = moment(d).add(dayGenerateDate - 1, "days")
        newEvents.push({
          start: moment(date.startOf("day")),
          end: moment(date.endOf("day")),
          allDay: true,
          title: " ",
        })
      })
    } else {
      var start = moment(prestationTypeSelected.start)
      var end = moment(prestationTypeSelected.end)
      var date = start.add(dayGenerateDate - 1, "days")
      while (date.isBefore(end)) {
        newEvents.push({
          start: moment(date.startOf("day")),
          end: moment(date.endOf("day")),
          allDay: true,
          title: " ",
        })
        date = date.add(7, "days")
      }
    }

    setPrestation({
      ...prestation,
      type: PrestationTypes.DATES_RECURRING_FOR_ALL,
      datePrestation: newEvents.map((d) => momentDateToString(d.start)),
    })
    setShowGenerateDateSelect(false)
    setShowPopupGenerateDate(false)
    setDayGenerateDate(0)
  }

  const prestationsUsers =
    prestation && prestation.prestationUser && prestation.prestationUser.length > 0
      ? prestation.prestationUser.map((pu) => ({
          value: pu.userId,
          label: users.find((u) => u.id == pu.userId).name,
        }))
      : []

  return (
    <Container fluid={true} className={"p-0" + (fixed ? " w-100 h-100" : "")}>
      {loading && (
        <div className="app-loading-spinner">
          <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
        </div>
      )}
      <Dialog
        maxWidth={false}
        onClose={onClosePopupGenerateDate}
        aria-labelledby="customized-dialog-title"
        open={showPopupGenerateDate}
      >
        <CustomDialogTitle onClose={onClosePopupGenerateDate}>Générer les dates</CustomDialogTitle>
        <DialogContent dividers className="d-flex flex-column p-3">
          {!showGenerateDateSelect ? (
            <Row className="justify-content-around">
              <Col xs="auto">
                <Button size="sm" type="submit" color="primary" onClick={onClosePopupGenerateDate}>
                  Non
                </Button>
              </Col>
              <Col xs="auto">
                <Button size="sm" type="submit" color="primary" onClick={() => setShowGenerateDateSelect(true)}>
                  Oui
                </Button>
              </Col>
            </Row>
          ) : (
            <div>
              <div className="small">
                {`Du ${stringDateToFormattedDate(prestationTypeSelected.start, false)} au ${stringDateToFormattedDate(
                  prestationTypeSelected.end,
                  false
                )}`}
              </div>
              <div className="d-flex mt-2">
                <InputSelect
                  title="Jour de la semaine"
                  variant="outlined"
                  style={{ width: "150px" }}
                  value={dayGenerateDate}
                  values={CONST_DAYS_WEEK}
                  handleChange={(e) => setDayGenerateDate(e.target.value)}
                />

                <Button size="sm" type="submit" color="primary" onClick={() => generateDates()}>
                  Générer les dates
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={false} onClose={() => setShowUserInfo(false)} open={showUserInfo}>
        <CustomDialogTitle onClose={() => setShowUserInfo(false)}>{userInfo?.name}</CustomDialogTitle>
        <DialogContent dividers className="d-flex flex-column p-3">
          {!userInfo ? (
            <ReactLoading color="rgba(0, 0, 0, 0.87)" type="spin" height="40" width="40" />
          ) : (
            <div>
              <div>Email : {userInfo.email}</div>
              <div>Licence : {userInfo.licence}</div>
              <div>Téléphone : {userInfo.phone}</div>
              <div>Adresse : {userInfo.address}</div>
              <div>Date de naissance : {userInfo.birthday?.split(" ")[0]}</div>
              {userInfo.nameToContact && (
                <div>
                  Personne à contacter 1 : {userInfo.nameToContact} au {userInfo.phoneToContact}
                </div>
              )}
              {userInfo.nameToContac2 && (
                <div>
                  Personne à contacter 2 : {userInfo.nameToContac2} au {userInfo.phoneToContact2}
                </div>
              )}
              <hr />
              <div>
                <div>Préstations</div>
                {userInfo.prestationUser && (
                  <div style={{ maxHeight: "250px", overflow: "auto" }}>
                    <table>
                      <tbody>
                        {userInfo.prestationUser.reverse().map((x) => {
                          let prestationName = `${x.prestation.template} ${x.prestation.comment ?? ""}`

                          return (
                            <tr style={{ backgroundColor: x.payed ? "" : "var(--color-warning-alpha)" }}>
                              <td>{prestationName}</td>
                              <td>{x.payed ? `Payée le ${stringDateToFormattedDate(x.payed, false)}` : "Non payée"}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      {prestation && (
        <ConditionalScrollBar show={fixed}>
          <div className="d-flex flex-column p-2 pb-6">
            <Panel label="Informations" className="mt-1">
              <Row className="m-0">
                {prestation.id > 0 ? (
                  <Col xs="auto" className="p-0 mt-2 mr-2">
                    <InputText
                      name="template"
                      title="Nom"
                      value={prestation.template}
                      style={{ width: "110px" }}
                      handleChange={onInputChange}
                    />
                  </Col>
                ) : (
                  <Col xs="auto" className="p-0 mt-2 mr-2">
                    <InputSelect
                      name="template"
                      title="Modèle"
                      variant="outlined"
                      value={prestation.template}
                      values={prestationsTemplates}
                      handleChange={onTemplateChange}
                    />
                  </Col>
                )}

                <Col xs="auto" className="p-0 mt-2 mr-2">
                  <InputText
                    name="price"
                    title={`Prix`}
                    type="price"
                    value={prestation.price}
                    style={{ width: "110px" }}
                    handleChange={onInputChange}
                  />
                </Col>

                <Col xs="auto" className="p-0 mt-2 mr-2">
                  <InputText
                    name="max"
                    title="Clients max"
                    type="number"
                    value={prestation.max}
                    style={{ width: "120px" }}
                    handleChange={onInputChange}
                  />
                </Col>

                <Col xs="auto" className="p-0 mt-2" style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    onClick={() => setShowColorPicker(true)}
                    style={{
                      margin: "auto",
                      padding: "5px",
                      background: "#fff",
                      borderRadius: "1px",
                      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "26px",
                        borderRadius: "2px",
                        backgroundColor: `${prestation.color}`,
                      }}
                    />
                  </div>
                  {showColorPicker && (
                    <div style={{ position: "absolute", zIndex: 1 }}>
                      <div
                        style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" }}
                        onClick={() => setShowColorPicker(false)}
                      />
                      <SketchPicker
                        name="color"
                        color={prestation.color ? prestation.color : ""}
                        onChange={(selectedColor) =>
                          setPrestation({
                            ...prestation,
                            color: selectedColor && selectedColor.hex ? selectedColor.hex : "#8989FF",
                          })
                        }
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="m-0">
                <Col xs="12" className="p-0 mt-2 mr-2">
                  <InputText
                    name="comment"
                    title="Commentaire"
                    style={{ width: "100%" }}
                    multiline
                    rows="3"
                    handleChange={onInputChange}
                    value={prestation.comment}
                  />
                </Col>
              </Row>
            </Panel>
            <Panel label="Date" className="mt-4">
              <div className="clearfix">
                <RadioGroup
                  className="flex-row w-100 justify-content-around"
                  name="type"
                  aria-label="prestationDateType"
                  value={prestation.type}
                  onChange={onDateTypeChange}
                >
                  <FormControlLabel
                    value={PrestationTypes.NO_DATE}
                    control={<Radio color="primary" />}
                    label="Pas de date"
                    labelPlacement="top"
                    style={{ margin: "0 10 0 0" }}
                  />
                  <FormControlLabel
                    value={PrestationTypes.DATES_FOR_EACH}
                    control={<Radio color="primary" />}
                    label="Date par utilisateur"
                    labelPlacement="top"
                    style={{ margin: "0 10 0 0" }}
                  />
                  <FormControlLabel
                    value={PrestationTypes.DATES_FOR_ALL}
                    control={<Radio color="primary" />}
                    label="Unique"
                    labelPlacement="top"
                    style={{ margin: "0 10 0 0" }}
                  />
                  <FormControlLabel
                    value={PrestationTypes.DATES_RECURRING_FOR_ALL}
                    control={<Radio color="primary" />}
                    label="Récurrent"
                    labelPlacement="top"
                    style={{ margin: "0 10 0 0" }}
                  />
                </RadioGroup>
              </div>
              {prestation.type === PrestationTypes.DATES_RECURRING_FOR_ALL ? (
                <div className="clearfix justify-content-center flex-column">
                  <div className="clearfix">
                    <InputText
                      name="timeStart"
                      label="Heure début"
                      defaultValue="17:30"
                      style={{ flex: "1 1 auto" }}
                      value={prestation.timeStart}
                      type="time"
                      handleChange={(e, value) => setPrestation({ ...prestation, timeStart: value })}
                    />

                    <InputText
                      name="timeEnd"
                      label="Heure de fin"
                      style={{ flex: "1 1 auto" }}
                      defaultValue="18:30"
                      value={prestation.timeEnd}
                      type="time"
                      handleChange={(e, value) => setPrestation({ ...prestation, timeEnd: value })}
                    />
                  </div>

                  <SmallCalendar events={getFormattedEventsForPresta()} onEventChange={onSlotSelect} />
                </div>
              ) : prestation.type === PrestationTypes.DATES_FOR_ALL ? (
                <div className="clearfix justify-content-center">
                  <Col xs="auto" className="p-0 mt-2 mr-1">
                    <InputDate
                      id="app-datetime-event-from"
                      name="start"
                      label="Du"
                      showTime={true}
                      style={{ flex: "1 1 auto" }}
                      selectsEnd
                      startDate={prestation.dateStart}
                      endDate={prestation.dateEnd}
                      value={prestation.dateStart}
                      handleChange={(e, value) => setPrestation({ ...prestation, dateStart: jsDateToString(value) })}
                    />
                  </Col>

                  <Col xs="auto" className="p-0 mt-2">
                    <InputDate
                      id="app-datetime-event-to"
                      name="end"
                      label="Au"
                      showTime={true}
                      style={{ flex: "1 1 auto" }}
                      selectsEnd
                      timeLeft={true}
                      startDate={prestation.dateStart}
                      endDate={prestation.dateEnd}
                      value={prestation.dateEnd}
                      handleChange={(e, value) => setPrestation({ ...prestation, dateEnd: jsDateToString(value) })}
                    />
                  </Col>
                </div>
              ) : null}
            </Panel>

            <Panel label="Visibilité" className="mt-4">
              <Row className="m-0 d-none">
                <Col xs="auto" className="p-0">
                  <InputSelectCheckbox
                    className="mb-0"
                    data={[{ id: "visible", name: "Visible aux utilisateurs", checked: prestation.visible }]}
                    handleChange={(newState) =>
                      setPrestation((oldState) => ({ ...oldState, visible: newState[0].checked ? 1 : 0 }))
                    }
                  />
                </Col>
              </Row>
              <Row className="m-0">
                <Col xs="auto" className="p-0">
                  <InputSelectCheckbox
                    className="mb-0"
                    data={[{ id: "visible", name: "Visible sur le calendrier", checked: prestation.visibleCalendar }]}
                    handleChange={(newState) =>
                      setPrestation((oldState) => ({ ...oldState, visibleCalendar: newState[0].checked ? 1 : 0 }))
                    }
                  />
                </Col>
              </Row>
            </Panel>

            <Panel label="Utilisateurs inscris" className="mt-4 w-100">
              <Select
                id="app-select-user"
                value={prestationsUsers}
                isMulti
                name="users"
                options={users}
                onChange={onUsersChange}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Panel>

            {data.id !== -1 ? (
              <Row className="m-0 mb-2">
                <Button
                  disabled={equals(prestation, originalPrestation) || loadingCreate}
                  className="mr-2"
                  size="sm"
                  type="submit"
                  color="primary"
                  onClick={() => updatePresta()}
                >
                  {loadingCreate ? (
                    <ReactLoading type="bubbles" height={26} width={26} />
                  ) : (
                    <span>
                      Sauvegarder <SaveOutlinedIcon />
                    </span>
                  )}
                </Button>
                <Button size="sm" onClick={cancelPresta} color="danger">
                  Annuler <CancelOutlinedIcon />
                </Button>
                <Button size="sm" onClick={onDeletePresta} color="danger">
                  <FaTrash /> SUPPRIMER
                </Button>
              </Row>
            ) : (
              <Row className="m-0 mb-2">
                <Button disabled={loadingCreate} className="" type="submit" color="primary" onClick={() => addPresta()}>
                  {loadingCreate ? <ReactLoading type="bubbles" height={26} width={26} /> : <span>Ajouter</span>}
                </Button>
              </Row>
            )}
          </div>
        </ConditionalScrollBar>
      )}
    </Container>
  )
}
