import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import Button from "../../components/Button";
import { InputText } from "../../components/Inputs";
import Notifier from "../../components/Notifier";
import { CONST_URI_API } from "../../helpers/const";
import { customFetch } from "../../helpers/fetch";
import { downloadFile } from "../../helpers/helpers";
import ReactLoading from "react-loading";

export default function Export() {
  const [emailParam, setEmailParam] = React.useState({
    popUpOpen: false,
    subject: "",
    content: "",
  });

  const onGenerateSummaryPrestationDay = () => {
    fetch(`${CONST_URI_API}generate?file=daily_prestations`)
      .then((resp) => resp.blob())
      .then((blob) => {
        downloadFile(blob, "prestation_journaliere.csv");
      });
  };

  const onSendEmailAllClients = () => {
    setEmailParam({ ...emailParam, loading: true });
    customFetch("POST", `${CONST_URI_API}email/sendemailall`, emailParam)
      .then((data) => {
        Notifier.showNotification("success", `Emails envoyés`);
        setEmailParam({ loading: false, popUpOpen: false });
      })
      .catch((e) => {
        console.error(e);
        setEmailParam({ ...emailParam, loading: false });
        Notifier.showNotification("error", `Erreur lors de le l'envoi des emails`);
      });
  };

  const onGenerateClients = () => {
    customFetch("GET", `${CONST_URI_API}generate?file=clients`).then((res) => {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob(["\ufeff", res]);

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = ".clients.csv";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  };

  return (
    <div>
      <Dialog
        maxWidth={false}
        onClose={() => setEmailParam({ ...emailParam, popUpOpen: false })}
        aria-labelledby="customized-dialog-title"
        open={emailParam.popUpOpen}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setEmailParam({ ...emailParam, popUpOpen: false })}>
          Envoi d'email à tous les clients
        </DialogTitle>
        <DialogContent dividers className="d-flex flex-column">
          <InputText
            name="subject"
            className="mb-3"
            title="Objet du mail"
            value={emailParam.subject}
            handleChange={(e, value) => setEmailParam({ ...emailParam, subject: value })}
          />
          <InputText
            name="content"
            title="Contenu du mail"
            variant="wysiwyg"
            handleChange={(e, value) => setEmailParam({ ...emailParam, content: value })}
          />

          <Button disabled={emailParam.loading} type="submit" onClick={onSendEmailAllClients} color="primary">
            {emailParam.loading ? (
              <ReactLoading type="bubbles" style={{ margin: "auto", height: "26px", width: "26px" }} />
            ) : (
              <span>Envoyer l'email à tous les clients</span>
            )}
          </Button>
        </DialogContent>
      </Dialog>

      <table>
        <tr>
          <td>
            <Button onClick={onGenerateSummaryPrestationDay}>Générer</Button>
          </td>
          <td>
            <label className="m-auto">Fichier excel permettant d'avoir le résumé des prestations payées dans la journée.</label>
          </td>
        </tr>
        <tr>
          <td>
            <Button onClick={onGenerateClients}>Générer</Button>
          </td>
          <td>
            <label className="m-auto">Fichier excel permettant d'avoir la liste des clients.</label>
          </td>
        </tr>
        <tr>
          <td>
            <Button onClick={() => setEmailParam({ ...emailParam, popUpOpen: true })}>Envoyer</Button>
          </td>
          <td>
            <label className="m-auto">Ouvrir une fenêtre permettant l'envoi d'un mail à tous les clients de la base de données.</label>
          </td>
        </tr>
      </table>
    </div>
  );
}
