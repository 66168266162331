import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { createDate } from "../../helpers/date";

registerLocale("fr", fr);

function InputDate({
  value,
  startDate,
  endDate,
  name,
  label,
  timeRange,
  placeholder,
  monthsShown = 1,
  showTime,
  dateFormat,
  timeLeft,
  variant,
  handleChange,
  className,
  ...other
}) {
  const [date, setDate] = useState(new Date());
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());

  // force to recall when props change
  useEffect(() => {
    if (value) {
      setDate(createDate(value));
    } else {
      setDate(null);
    } 

    if (startDate) {
      setDate1(createDate(startDate));
    } else {
      setDate1(null);
    }

    if (endDate) {
      setDate2(createDate(endDate));
    } else {
      setDate2(null);
    }
  }, [value, startDate, endDate]);

  const onChange = (newDate) => {
    setDate(newDate);
    if (handleChange !== undefined) {
      const event = {
        target: {
          name: name,
          type: "datetime",
        },
      };
      handleChange(event, newDate);
    }
  };

  return (
    <>
      <DatePicker
        selected={date}
        onChange={onChange}
        todayButton="Aujourd'hui"
        placeholderText={placeholder}
        showTimeSelect={showTime}
        locale="fr"
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat={`${dateFormat}${showTime ? " HH:mm" : ""}`}
        monthsShown={monthsShown}
        className={`app-date-picker ${className}`}
        popperClassName={`${timeLeft ? "time-left" : ""}`}
        {...{ startDate: date1 !== "" ? date1 : undefined, endDate: date2 !== "" ? date2 : undefined }}
        {...other}
      />
      <label className="app-date-picker-label">{label}</label>
    </>
  );
}

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleChange: PropTypes.func,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  dateFormat: PropTypes.string
};

InputDate.defaultProps = {
  timeRange: false,
  showTime: false,
  timeLeft: false,
  placeholder: "",
  value: "",
  startDate: "",
  endDate: "",
  dateFormat: "dd/MM/yyyy"
};

export default InputDate;
