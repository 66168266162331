import Axios from "axios";
import GlobalService from "./_GlobalService";


class PrestationTypeService extends GlobalService {

    constructor() {
        super("prestationstypes");
    }
    

};

export default new PrestationTypeService();