import { CONST_URI_API } from "../helpers/const";
import { post, customFetch } from "./fetch";
import Cookies from "js-cookie";

//const setUser = user => window.localStorage.setItem("gatsbyUser", JSON.stringify(user));
//const setToken = token => window.localStorage.setItem("token", token);
const setToken = token => Cookies.set("__session", token);
//export const getToken = () => window.localStorage.getItem("token");
export const getToken = () => Cookies.get("__session");

export const handleLogin = ({ username, password }) =>
  new Promise((resolve, reject) => {
    customFetch(
      "POST",
      `${CONST_URI_API}users/login`,
      { username, password, application: "test" },
      { credentials: "include" }
    )
      .then((token) => {
        setToken(token);
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const isLoggedIn = () => {
  //const jwt = Cookies.get("__session");
  const jwt = getToken();
  return jwt ? true : false;
};

export const logout = (callback) => {
  setToken(null);
  Cookies.remove("__session");
  callback();
};
