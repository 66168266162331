export const CONST_URI_API = process.env.REACT_APP_API_URL || "http://localhost:5000/api/v1/";
export const CONST_WIDTH_MOBILE = 900;
export const CONST_CALENDAR_MESSAGES = {
  // new
  allDay: "Jour",
  previous: "<",
  next: ">",
  today: "Aujourd'hui",
  month: "Mois",
  week: "Semaine",
  day: "Jour",
  agenda: "Agenda",
  date: "Date",
  time: "Temps",
  event: "Évènement",
};

export const CONST_DAYS_WEEK = [
  {
    title:  "Lundi",
    value: 1
  },
  {
    title:  "Mardi",
    value: 2
  },
  {
    title:  "Mercredi",
    value: 3
  },
  {
    title:  "Jeudi",
    value: 4
  },
  {
    title:  "Vendredi",
    value: 5
  },
  {
    title:  "Samedi",
    value: 6
  },
  {
    title:  "Dimanche",
    value: 7
  }
]

export const PrestationTypes = {
  NO_DATE: 0,
  DATES_FOR_ALL: 1,
  DATES_RECURRING_FOR_ALL:2,
  DATES_FOR_EACH:3,
}

export const PRESTATION_TYPES_OPTIONS = [
  {
    title:  "Dates uniques",
    value: PrestationTypes.DATES_FOR_ALL
  },
  {
    title:  "Dates récurrentes",
    value: PrestationTypes.DATES_RECURRING_FOR_ALL
  },
  {
    title:  "Dates pour chaque utilisateur",
    value: PrestationTypes.DATES_FOR_EACH
  }
]