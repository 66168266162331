import React, { Component } from "react";
import ReactLoading from "react-loading";
import Notifier from "../../components/Notifier";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { InputText } from "../../components/Inputs";
import { Scrollbar } from "../../components/Scrollbar";
import Button from "../../components/Button";
import { RoleService } from "../../services/Services";
import Table from "../../components/Table";
import { isLoggedIn } from "../../helpers/auth";
import { Redirect } from "react-router-dom";

export default class Role extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      addItemPopUpopen: false,
      newItem: {},
      loading: false,
    };

    this.handleNewChange = this.handleNewChange.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.refresh();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refresh = () => {
    this.setState({ loading: true });
    RoleService.getAll()
      .then((items) => {
        if (this._isMounted) this.setState({ items: items, loading: false });
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de la récupération des données`);
        this.setState({ loading: false });
      });
  };

  onDelete = (item) => {
    RoleService.delete(item.id)
      .then(() => {
        this.refresh();
        Notifier.showNotification("success", `Le rôle "${item.name}" à bien été supprimé`);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Impossible de supprimer le rôle`);
        console.error(e);
      });
  };

  onAddItem = () => {
    RoleService.create(this.state.newItem)
      .then((response) => {
        if (response > 0) {
          Notifier.showNotification("success", `Le rôle "${this.state.newItem.name}" à bien été ajouté`);
          this.refresh();
          this.setState({
            addItemPopUpopen: false,
            newItem: {},
          });
        }
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de l'ajout`);
        console.error(e);
      });
  };

  showAddItemPopUp = () => {
    this.setState({ addItemPopUpopen: true });
  };

  closeAddItemPopUp = () => {
    this.setState({ addItemPopUpopen: false });
  };

  handleNewChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      newItem: {
        ...prevState.newItem,
        [name]: value,
      },
    }));
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div className="app-loading-spinner">
            <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
          </div>
        )}
        <div className="container">
          <div className="d-flex m-3 justify-content-between">
            <h1>Liste des rôles</h1>
            <div className="">
              <IconButton onClick={this.showAddItemPopUp}>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
            <Dialog onClose={this.closeAddItemPopUp} aria-labelledby="customized-dialog-title" open={this.state.addItemPopUpopen}>
              <DialogTitle id="customized-dialog-title" onClose={this.closeAddItemPopUp}>
                Ajout d'un rôle
              </DialogTitle>
              <DialogContent dividers className="d-flex flex-column p-3">
                <InputText
                  name="name"
                  className="mb-4"
                  title="Nom du rôle"
                  value={this.state.newItem.name}
                  variant="standard"
                  handleChange={this.handleNewChange}
                />
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={this.onAddItem} color="primary">
                  Ajouter
                </Button>
              </DialogActions>
            </Dialog>
          </div>
            <div className="m-2 p-2 shadowed">
              <Table
                columns={[{ title: "Roles", field: "name" }]}
                className="app-table-rectangle"
                data={this.state.items}
                handleDelete={this.onDelete}
                style={{ backgroundColor: "#fff" }}
              />
            </div>
        </div>
      </>
    );
  }
}
