import React, { Component } from 'react';
import PropTypes from 'prop-types';

class List extends Component {
    render() {
        const { children, ...other } = this.props;
        return (
            <ul className="app-list row">
                {children}
            </ul>
        )
    }
}

List.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default List;