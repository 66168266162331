import React, { Component } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import EditPrestationPanel from "./EditPrestationPanel";
import Button from "../../components/Button";
import { CONST_WIDTH_MOBILE } from "../../helpers/const";
import CustomDialogTitle from "../../components/Dialog/dialog-title";

class CreatePrestation extends Component {
  constructor() {
    super();
    this.state = {
      popUpOpen: false,
    };

    this.isMobile = window.innerWidth < CONST_WIDTH_MOBILE;
  }

  onItemCreate = () => {
    this.setState({ popUpOpen: false });
    if (this.props.handleCreate) {
      this.props.handleCreate();
    }
  };

  render() {
    const {
      state: { popUpOpen },
    } = this;

    const handleClose = () => {
      this.setState({ popUpOpen: false });
    };

    return (
      <div style={{ position: "fixed", bottom: "15px", right: "15px", zIndex: 1 }}>
        <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={popUpOpen}>
          <CustomDialogTitle onClose={handleClose}>
            Création préstation
          </CustomDialogTitle>
          <DialogContent
            dividers
            className="d-flex flex-column p-0"
            style={{ width: this.isMobile ? "80vw" : "50vw", height: this.isMobile ? "70vh" : "75vh" }}
          >
            <EditPrestationPanel data={{ id: -1 }} handleChange={this.onItemCreate} />
          </DialogContent>
        </Dialog>
        <Button
          onClick={() => this.setState({ popUpOpen: true })}
          style={{ boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)", borderRadius: "2rem" }}
          color="primary"
        >
          Ajouter une prestation
        </Button>
      </div>
    );
  }
}

export default CreatePrestation;
