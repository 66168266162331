import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import { Scrollbar } from "../Scrollbar";

require("./style.css");

export default class Tabs extends Component {
  static propTypes = {
    style: PropTypes.oneOf(["tabbed", "rounded"]),
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      activeTab: this.props.children[0].props.label,
    };
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    this.setState({ height: height - 70 });
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: { theme, split, children, fullHeight },
      state: { activeTab },
    } = this;
    return (
      <div
        ref={(divElement) => {
          this.divElement = divElement;
        }}
        className={`tabs tabs-${theme} ${split ? "split" : ""} ${fullHeight ? " h-100 d-flex flex-column" : ""}`}
      >
        <div className="d-flex">
          <div style={{ flexGrow: "1" }}>
            <ol className={`tab-list d-flex shadowed`} style={{ overflow: "auto" }}>
              {children.map((child) => {
                const { label } = child.props;
                return <Tab activeTab={activeTab} key={label} label={label} handleClick={onClickTabItem} className={``} />;
              })}
            </ol>
          </div>
        </div>
        {fullHeight ? (
          <div style={{ height: fullHeight ? this.state.height : "" }} className={`${fullHeight ? " d-flex flex-column shadowed" : ""}`}>
            <Scrollbar>
              <div className="tab-content p-2">
                {children.map((child) => {
                  if (child.props.label !== activeTab) return undefined;
                  return child.props.children;
                })}
              </div>
            </Scrollbar>
          </div>
        ) : (
          <div className="tab-content p-2">
            {children.map((child) => {
              if (child.props.label !== activeTab) return undefined;
              return child.props.children;
            })}
          </div>
        )}
      </div>
    );
  }
}
