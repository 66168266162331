import React, { useState, useEffect } from "react"
import Table from "../components/Table"
import PrestationGroupService from "../services/PrestationGroupService"
import ReactLoading from "react-loading"
import Notifier from "../components/Notifier"

export default function PrestationGroup() {
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    setLoading(true)
    PrestationGroupService.getAll()
      .then((items) => {
        setGroups(items)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la récupération des données`)
        setLoading(false)
      })
  }

  const onItemCreate = (item) => {
    PrestationGroupService.create(item)
      .then(() => {
        refresh()
        Notifier.showNotification("success", `Le groupe à bien été créé`)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la création`)
      })
  }

  const onItemEdit = (item) => {
    PrestationGroupService.update(item)
      .then(() => {
        refresh()
        Notifier.showNotification("success", `Le groupe à bien été modifié`)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la modification`)
      })
  }

  const onItemDeleted = (item) => {
    PrestationGroupService.delete(item.id)
      .then(() => {
        refresh()
        Notifier.showNotification("success", `Le groupe  à bien été supprimée`)
      })
      .catch((e) => {
        console.error(e)
        Notifier.showNotification("error", `Erreur lors de la suppression`)
      })
  }

  if (loading)
    return (
      <div className="app-loading-spinner">
        <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
      </div>
    )

  return (
    <div>
      <div className="shadowed w-100 h-100 p-6" style={{ backgroundColor: "#fff" }}>
        <Table
          data={groups}
          columns={[{ title: "Nom", field: "name" }]}
          handleAdd={onItemCreate}
          handleEdit={onItemEdit}
          handleDelete={(item) => onItemDeleted(item)}
          options={{
            search: true,
            noPadding: false,
            inlineAddAndEdit: true,
          }}
          style={{ backgroundColor: "#fff", marginBottom: "80px" }}
        >
          <colgroup>
            <col span="1" style={{ width: "26%" }} />
            <col span="1" style={{ width: "26%" }} />
            <col span="1" style={{ width: "5%" }} />
            <col span="1" style={{ width: "14%" }} />
            <col span="1" style={{ width: "14%" }} />
            <col span="1" style={{ width: "5%" }} />
            <col span="1" style={{ width: "4%" }} />
            <col span="1" style={{ width: "4%" }} />
            <col span="1" style={{ width: "4%" }} />
            <col span="1" style={{ width: "60px" }} />
          </colgroup>
        </Table>
      </div>
    </div>
  )
}
