import React, { Component } from "react";
import Notifier from "../../components/Notifier";
import { List, ListItem } from "../../components/List";
import Label from "../../components/Label";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Tooltip, Popover, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { CoffeeTransactionService } from "../../services/Services";
import { InputDate, InputText } from "../../components/Inputs";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Scrollbar } from "../../components/Scrollbar";
import Button from "../../components/Button";
import EditPrestationPanel from "../Prestation/EditPrestationPanel";
import { jsDateToString, stringDateToFormattedDate } from "../../helpers/date";
import ReactLoading from "react-loading";
import { customFetch } from "../../helpers/fetch";
import { CONST_URI_API, CONST_WIDTH_MOBILE } from "../../helpers/const";

export default class CoffeeTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemEditing: null,
      items: [],
      addItemPopUpopen: false,
      newItem: {
        date: jsDateToString(new Date())
      },
      loadingCreate: false,
      total: 0,
    };
    this.isMobile = window.innerWidth < CONST_WIDTH_MOBILE;

    this.handleChange = this.handleChange.bind(this);
    this.handleNewChange = this.handleNewChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.refresh();
  }

  refresh = async () => {
    CoffeeTransactionService.getAll().then((items) => {
      if (this._isMounted) this.setState({ items: items, addItemPopUpopen: false });
    });

    customFetch("GET", `${CONST_URI_API}stats/coffee`)
      .then((data) => {
        if (this._isMounted) this.setState({ total: data });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onEdit = (item) => {
    this.setState({ itemEditing: item });
  };

  onEditSave = () => {
    CoffeeTransactionService.update(this.state.itemEditing)
      .then(() => {
        const newItem = this.state.itemEditing;
        this.setState({
          items: this.state.items.map((item) => (item.id == newItem.id ? newItem : item)),
          itemEditing: null,
        });
        this.refresh();
        Notifier.showNotification("success", `Transaction sauvegardée`);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Impossible de sauvegarder : ${e}`);
        console.error(e);
      });
  };

  onEditCancel = () => {
    this.setState({ itemEditing: null });
  };

  onDelete = (item) => {
    CoffeeTransactionService.delete(item.id)
      .then(() => {
        this.refresh();
        Notifier.showNotification("success", `La transaction a bien été supprimé`);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Impossible de supprimer : ${e}`);
        console.error(e);
      });
  };

  onCreate = () => {
    CoffeeTransactionService.create(this.state.newItem)
      .then((response) => {
        if (response > 0) {
          Notifier.showNotification("success", `La transaction a bien été ajoutée`);
          this.setState({
            addItemPopUpopen: false
          });
          this.refresh();
        }
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de l'ajout : ${e}`);
        console.error(e);
      });
  };

  showAddItemPopUp = () => {
    this.setState({ addItemPopUpopen: true });
  };

  closeAddItemPopUp = () => {
    this.setState({ addItemPopUpopen: false });
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      itemEditing: {
        ...prevState.itemEditing,
        [name]: value,
      },
    }));
  }

  handleNewChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      newItem: {
        ...prevState.newItem,
        [name]: value,
      },
    }));
  }

  render() {
    const { items, itemEditing, newItem, loadingCreate, total } = this.state;

    return (
      <Scrollbar>
        <Dialog maxWidth={false} onClose={this.closeAddItemPopUp} aria-labelledby="customized-dialog-title" open={this.state.addItemPopUpopen}>
          <DialogTitle id="customized-dialog-title" onClose={this.closeAddItemPopUp}>
            Ajout d'une transaction
          </DialogTitle>
          <DialogContent dividers className="d-flex flex-column p-3" style={{ padding: "20px" }}>
            <div style={{ position: "relative" }}>
              <InputDate
                name="date"
                label="Date"
                showTime={false}
                style={{ flex: "1 1 auto" }}
                value={newItem.date}
                handleChange={(e, value) => this.setState({ newItem: { ...newItem, date: jsDateToString(value) } })}
              />
            </div>
            <InputText
              name="price"
              title={`Prix`}
              type="price"
              className="mt-2"
              value={newItem.price}
              style={{ flex: "1 1 auto" }}
              handleChange={(e, value) => this.setState({ newItem: { ...newItem, price: value } })}
            />
            <Button disabled={loadingCreate} className="mt-2" type="submit" color="primary" onClick={() => this.onCreate()}>
              {loadingCreate ? <ReactLoading type="bubbles" height={26} width={26} /> : <span>Ajouter</span>}
            </Button>
          </DialogContent>
        </Dialog>
        <div className="container">
          <div>
            <Button className="mt-3" onClick={this.showAddItemPopUp} color="primary">
              Ajouter une transaction
            </Button>
            <div>
              Gain total : {total > 0 ? "+" : ""}
              {total}€
            </div>
          </div>
          <List>
            {items.length > 0 &&
              items.map((item, i) => {
                if (itemEditing == null || itemEditing.id !== item.id) {
                  return (
                    <ListItem key={item.id} className="shadowed">
                      <Label className="col-md-6 m-auto" label="Date" color="purple">
                        {stringDateToFormattedDate(item.date, false)}
                      </Label>
                      <Label className="col-md-4 m-auto" label="Prix" color="yellow">
                        {item.price > 0 ? "+" : ""}
                        {item.price}€
                      </Label>
                      <div className="col-md-2 m-auto">
                        <Tooltip title="Éditer" aria-label="Éditer" arrow>
                          <IconButton className="m-auto" color="primary" onClick={() => this.onEdit(item)}>
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer" arrow>
                          <IconButton className="m-auto" color="secondary" onClick={() => this.onDelete(item)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem key={itemEditing.id} className="shadowed">
                      <div className="col-md-6 m-auto">
                        <InputDate
                          name="date"
                          label="Date"
                          showTime={false}
                          value={itemEditing.date}
                          handleChange={(e, value) => this.setState({ itemEditing: { ...itemEditing, date: jsDateToString(value) } })}
                        />
                      </div>
                      <div className="col-md-4 m-auto">
                        <InputText
                          handleChange={this.handleChange}
                          name="price"
                          type="price"
                          title="Prix"
                          value={itemEditing.price}
                          variant="standard"
                        />
                      </div>
                      <div className="col-md-2 m-auto">
                        <Tooltip title="Éditer" aria-label="Éditer" arrow>
                          <IconButton className="m-auto" color="primary" onClick={this.onEditSave}>
                            <SaveOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Annuler" arrow>
                          <IconButton className="m-auto" color="secondary" onClick={this.onEditCancel}>
                            <CancelOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </ListItem>
                  );
                }
              })}
          </List>
        </div>
      </Scrollbar>
    );
  }
}
