import { DialogTitle, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { PureComponent } from "react";

const CustomDialogTitle = ({ onClose, children }) => {
  return (
    <DialogTitle id="customized-dialog-title" onClose={onClose}>
      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
        <span>{children}</span>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
    </DialogTitle>
  );
};

export default CustomDialogTitle;
