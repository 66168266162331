import React, { Component } from 'react';  
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';



class InputSelect extends Component {
    constructor(props) {  
        super(props);  
        this.state = {
            value: props.value
        }
    }

    handleChange = event => {
        const target = event.target;
        var value;
        switch(target.type) {
            case 'number':
                value = parseInt(target.value);
                break;
            default:
                value = target.value;
        }
        this.setState({value});
        if(this.props.handleChange != undefined)
            this.props.handleChange(event, value);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.setState({value: this.state.value});
        }
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.value !== prevState.value){
            return {value : nextProps.value};
        }
        else return null;
    }

    render() {
        const {name, value, values, title, variant = "outlined", handleChange, className, ...other} = this.props;

        if(value == undefined || value === ""){
            this.state.value = values[0]?.value;
        }

        return (
        <FormControl variant={variant} className={className} >
            <InputLabel ref={null} id={`id-${name}`} style={{backgroundColor:"#fff", padding:"0px 3px"}}>{title}</InputLabel>
            <Select
                size="small"
                name={name}
                labelId={`id-${name}`}
                value={this.state.value}
                onChange={this.handleChange}
                displayEmpty
                autoWidth
                {...other}>
                    <MenuItem value="cacaprout">
                        <em>Sélectionner</em>
                    </MenuItem>
                    {
                    values.map(choice => {
                        return (
                            <MenuItem key={choice.value} value={choice.value}>{choice.title}</MenuItem>
                        )
                    })
                    }
            </Select>
        </FormControl>
        );
    }
}

InputSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    title: PropTypes.string,
    values: PropTypes.array.isRequired,
    handleChange: PropTypes.func
};

export default InputSelect;