import Axios from "axios";
import GlobalService from "./_GlobalService";
import { customFetch } from "../helpers/fetch";

class UserService extends GlobalService {
  constructor() {
    super("users");
  }

  disable = (userId) =>
    new Promise((resolve, reject) => {
      customFetch("GET", `${this.baseUrl}/${userId}/disable`)
        .then((response) => {
          resolve();
        })
        .catch((e) => reject(e));
    });

  updateRoles = (userId, roles) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/${userId}/updateroles`, roles)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });

  getAllShort = () =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/search`, { short: true })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });

  getAllMinimized = () =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/search`, { minimized: true })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
}

export default new UserService();
