import React from "react";
import { Row, Col } from "reactstrap";
import { Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, IconButton } from "@material-ui/core";
import { PrestationTypeService } from "../../services/Services";
import { InputDate, InputSelect, InputSelectCheckbox, InputText } from "../../components/Inputs";
import Panel from "../../components/Panel";
import { SketchPicker } from "react-color";
import Notifier from "../../components/Notifier";
import ReactLoading from "react-loading";
import { equals } from "../../helpers/helpers";
import Button from "../../components/Button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { CONST_CALENDAR_MESSAGES, PrestationTypes, PRESTATION_TYPES_OPTIONS } from "../../helpers/const";
import { createDate, getMomentDate, jsDateToString, momentDateToString } from "../../helpers/date";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialogTitle from "../../components/Dialog/dialog-title";
import SmallCalendar from "../../components/Calendar/SmallCalendar";

const localizer = momentLocalizer(moment);

export default function EditPrestationTypeDialog(props) {
  const { data, open, handleClose, handleUpdate } = props;

  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [prestationType, setPrestationType] = React.useState({});
  const [originalPrestationType, setOriginalPrestationType] = React.useState({});
  const [opened, setOpened] = React.useState(false);
  const [showColorPicker, setShowColorPicker] = React.useState(false);

  React.useEffect(() => {
    setOpened(open);
    if (data && data.id > 0) {
      setPrestationType(data);
      setOriginalPrestationType(JSON.parse(JSON.stringify(data)));
    } else {
      setPrestationType({
        dates: [],
      });
      setOriginalPrestationType({});
    }
  }, [data, open]);

  const onInputChange = (event, value) => {
    setPrestationType({ ...prestationType, [event.target.name]: value });
  };

  const onCancel = () => {
    handleClose();
  };

  function onEdit() {
    setLoadingCreate(true);
    PrestationTypeService.update(prestationType)
      .then(() => {
        Notifier.showNotification("success", `Informations sauvegardées`);
        setOriginalPrestationType(prestationType);
        setLoadingCreate(false);
        if (handleClose) handleClose();
        if (handleUpdate) handleUpdate();
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de la sauvegarde : ${e}`);
        console.error(e);
        setLoadingCreate(false);
      });
  }

  const getFormattedEvents = () => {
    return (
      prestationType.datePrestationType &&
      prestationType.datePrestationType.map((date) => ({
        start: moment(date).startOf("day"),
        end: moment(date).endOf("day"),
        allDay: true,
        title: " ",
      }))
    );
  };

  const onSlotSelect = (events) => {
    /*const newEvent = {
      start: moment(start).startOf("day"),
      end: moment(start).endOf("day"),
      allDay: true,
      title: " ",
    };

    const alreadyExists = events.find((e) => e.start.toString() === newEvent.start.toString());

    if (alreadyExists) {
      const index = events.indexOf(alreadyExists);
      events.splice(index, 1);
      prestationType.datePrestationType.splice(index, 1);
      setEvents(events);
      setPrestationType({ ...prestationType, datePrestationType: prestationType.dates });
    } else {
      setEvents([...events, newEvent]);
      setPrestationType({
        ...prestationType,
        datePrestationType: [...prestationType.datePrestationType, { date: momentDateToString(newEvent.start) }],
      });*/
    setPrestationType({ ...prestationType, datePrestationType: events.map((e) => momentDateToString(e.start)) });
  };

  function onCreate() {
    setLoadingCreate(true);
    PrestationTypeService.create(prestationType)
      .then((response) => {
        if (response > 0) {
          Notifier.showNotification("success", `La préstation "${prestationType.name}" à bien été ajoutée`);
          if (handleClose) handleClose();
          if (handleUpdate) handleUpdate();
        }
        setLoadingCreate(false);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de l'ajout : ${e}`);
        setLoadingCreate(false);
        console.error(e);
      });
  }

  return (
    <div style={{ position: "fixed", bottom: "15px", right: "15px", zIndex: 1 }}>
      <Dialog maxWidth={false} onClose={onCancel} aria-labelledby="customized-dialog-title" open={opened}>
        <CustomDialogTitle onClose={onCancel}>{prestationType.id ? "Ajout" : "Edition"} modèle de préstation</CustomDialogTitle>
        <DialogContent dividers className="d-flex flex-column p-3" style={{ width: "50vw", height: "75vh" }}>
          <div className="d-flex flex-column p-2 pb-6">
            <Panel label="Informations" className="mt-1">
              <Row className="m-0">
                <Col xs="auto" className="p-0 mt-2 mr-2">
                  <InputText name="name" title="Nom du type" handleChange={onInputChange} value={prestationType.name} />
                </Col>
                <Col xs="auto" className="p-0 mt-2 mr-2">
                  <InputText
                    name="price"
                    title={`Prix`}
                    type="price"
                    value={prestationType.price}
                    style={{ width: "110px" }}
                    handleChange={onInputChange}
                  />
                </Col>

                <Col xs="auto" className="p-0 mt-2 mr-2">
                  <InputText
                    name="max"
                    title="Clients max"
                    type="number"
                    value={prestationType.max}
                    style={{ width: "120px" }}
                    handleChange={onInputChange}
                  />
                </Col>

                <Col xs="auto" className="p-0 mt-2" style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    onClick={() => setShowColorPicker(true)}
                    style={{
                      margin: "auto",
                      padding: "5px",
                      background: "#fff",
                      borderRadius: "1px",
                      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: "36px",
                        height: "26px",
                        borderRadius: "2px",
                        backgroundColor: `${prestationType.color}`,
                      }}
                    />
                  </div>
                  {showColorPicker && (
                    <div style={{ position: "absolute", zIndex: 1 }}>
                      <div
                        style={{ position: "fixed", top: "0px", right: "0px", bottom: "0px", left: "0px" }}
                        onClick={() => setShowColorPicker(false)}
                      />
                      <SketchPicker
                        name="color"
                        color={prestationType.color ? prestationType.color : ""}
                        onChange={(selectedColor) =>
                          setPrestationType({ ...prestationType, color: selectedColor && selectedColor.hex ? selectedColor.hex : "#8989FF" })
                        }
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Panel>

            <Panel label="Dates" className="mt-1 ">
              <div className="d-flex flex-column mt-1">
                <InputSelect
                  name="Types de prestations"
                  title="Types de prestations"
                  variant="outlined"
                  value={prestationType.type ?? 0}
                  values={PRESTATION_TYPES_OPTIONS}
                  handleChange={(e) => setPrestationType({ ...prestationType, type: e.target.value })}
                />

                {prestationType.type == PrestationTypes.DATES_RECURRING_FOR_ALL ? (
                  <div className="mt-2">
                    <SmallCalendar events={getFormattedEvents()} onEventChange={onSlotSelect} />
                  </div>
                ) : prestationType.type == PrestationTypes.DATES_FOR_ALL ? (
                  <div className="d-flex">
                    <Col xs="auto" className="p-0 mt-2 mr-1">
                      <InputDate
                        id="app-datetime-event-from"
                        name="start"
                        label="Du"
                        dateFormat="yyyy-MM-dd"
                        style={{ flex: "1 1 auto" }}
                        selectsEnd
                        startDate={prestationType.start}
                        endDate={prestationType.end}
                        value={prestationType.start}
                        handleChange={(e, value) => setPrestationType({ ...prestationType, start: jsDateToString(value) })}
                      />
                    </Col>

                    <Col xs="auto" className="p-0 mt-2">
                      <InputDate
                        id="app-datetime-event-to"
                        name="end"
                        label="Au"
                        dateFormat="yyyy-MM-dd"
                        style={{ flex: "1 1 auto" }}
                        selectsEnd
                        timeLeft={true}
                        startDate={prestationType.start}
                        endDate={prestationType.end}
                        value={prestationType.end}
                        handleChange={(e, value) => setPrestationType({ ...prestationType, end: jsDateToString(value) })}
                      />
                    </Col>
                  </div>
                ) : prestationType.type == PrestationTypes.DATES_FOR_EACH ? (
                  <>Le paramétrage se fera donc dans chaque utilisateur</>
                ) : null}
              </div>
            </Panel>

            <Panel label="Visibilité" className="mt-4">
              <Row className="m-0">
                <Col xs="auto" className="p-0">
                  <InputSelectCheckbox
                    className="mb-0"
                    data={[{ id: "visible", name: "Visible sur le calendrier", checked: prestationType.visibleCalendar }]}
                    handleChange={(newState) => setPrestationType({ ...prestationType, visibleCalendar: newState[0].checked ? 1 : 0 })}
                  />
                </Col>
              </Row>
            </Panel>

            <Row className="m-0 mb-2 flex-row-reverse">
              {prestationType.id > 0 ? (
                <>
                  <Button
                    disabled={equals(prestationType, originalPrestationType) || loadingCreate}
                    className="mr-2"
                    type="submit"
                    color="primary"
                    onClick={onEdit}
                  >
                    {loadingCreate ? <ReactLoading type="bubbles" height={26} width={26} /> : <span>Sauvegarder</span>}
                  </Button>
                  <Button onClick={handleClose} color="danger">
                    Annuler
                  </Button>
                </>
              ) : (
                <Button disabled={loadingCreate} className="" type="submit" color="primary" onClick={onCreate}>
                  {loadingCreate ? <ReactLoading type="bubbles" height={26} width={26} /> : <span>Ajouter</span>}
                </Button>
              )}
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
