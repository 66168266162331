import React, { Component } from "react";
import ReactLoading from "react-loading";
import Notifier from "../../components/Notifier";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { PrestationTypeService } from "../../services/Services";
import { Scrollbar } from "../../components/Scrollbar";
import EditPrestationPanel from "../Prestation/EditPrestationPanel";
import Table from "../../components/Table";
import EditPrestationTypeDialog from "./EditPrestationTypeDialog";

export default class PrestationsTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemEditing: null,
      items: [],
      addItemPopUpopen: false,
      newItem: {},
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNewChange = this.handleNewChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.refresh();
  }

  refresh = async () => {
    this.setState({ loading: true });
    PrestationTypeService.getAll()
      .then((items) => {
        if (this._isMounted) this.setState({ items: items, addItemPopUpopen: false, loading: false });
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de la récupération des données`);
        this.setState({ loading: false });
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onDelete = (item) => {
    PrestationTypeService.delete(item.id)
      .then(() => {
        this.refresh();
        Notifier.showNotification("success", `La préstation "${item.name}" à bien été supprimé`);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Impossible de supprimer la préstation`);
        console.error(e);
      });
  };



  showAddItemPopUp = (itemEditing) => {
    if (itemEditing) {
      this.setState({
        addItemPopUpopen: true,
        itemEditing: { ...itemEditing, mode: "template_edit" },
      });
    } else {
      this.setState({
        addItemPopUpopen: true,
        itemEditing: { id: -2 },
      });
    }
  };

  closeAddItemPopUp = () => {
    this.setState({ addItemPopUpopen: false });
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      itemEditing: {
        ...prevState.itemEditing,
        [name]: value,
      },
    }));
  }

  handleNewChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      newItem: {
        ...prevState.newItem,
        [name]: value,
      },
    }));
  }

  render() {
    const { items, itemEditing, loading } = this.state;

    return (
      <>
        {loading && (
          <div className="app-loading-spinner">
            <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
          </div>
        )}
        <EditPrestationTypeDialog handleUpdate={this.refresh} handleClose={() => this.setState({ addItemPopUpopen: false })} open={this.state.addItemPopUpopen} data={itemEditing} />
        <div className="m-2 p-2 shadowed">
          <Table
            columns={[
              { title: "Nom", field: "name" },
              { title: "Prix", field: "price" },
            ]}
            className="app-table-rectangle"
            data={this.state.items}
            handleAdd={() => this.showAddItemPopUp()}
            handleEdit={(item) => this.showAddItemPopUp(item)}
            options={{
              search: true,
            }}
            handleDelete={this.onDelete}
          />
        </div>
      </>
    );
  }
}
