import React, { Component } from "react";
import { CONST_CALENDAR_MESSAGES } from "../../helpers/const";
import { Calendar as RBCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import PropTypes from "prop-types";

const localizer = momentLocalizer(moment);

class SmallCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: this.props.events,
    };

    this.onSlotSelect = this.onSlotSelect.bind(this);
  }

  componentDidMount() {
    this.setState({
      events: this.props.events,
    });
  }

  onSlotSelect = ({ start }) => {
    const newEvent = {
      start: moment(start).startOf("day"),
      end: moment(start).endOf("day"),
      allDay: true,
      title: " ",
    };

    const alreadyExists = this.state.events.find((e) => e.start.toString() === newEvent.start.toString());

    var newEvents = this.state.events;
    if (alreadyExists) {
      const index = newEvents.indexOf(alreadyExists);
      newEvents.splice(index, 1);
    } else {
      newEvents = [...this.state.events, newEvent];
    }
    this.setState({ events: newEvents });

    if (this.props.onEventChange) {
      this.props.onEventChange(newEvents);
    }
    if (this.props.onSlotSelect) {
      this.props.onSlotSelect(newEvent.start);
    }
  };

  render() {
    const { details, events } = this.props;
    const {  } = this.state;

    return (
      <div>
        <RBCalendar
          defaultDate={new Date()}
          defaultView="month"
          culture="fr"
          messages={CONST_CALENDAR_MESSAGES}
          localizer={localizer}
          events={events}
          onSelectSlot={this.onSlotSelect}
          selectable
          views={["month"]}
          min={new Date(0, 0, 0, 6, 0, 0)}
          max={new Date(0, 0, 0, 22, 0, 0)}
          style={{ height: "240px" }}
        />
        {details && (
          <div className="clearfix">
            <span className="font-italic">({events.length} dates sélectionnées)</span>
          </div>
        )}
      </div>
    );
  }
}

SmallCalendar.propTypes = {
  details: PropTypes.bool,
  events: PropTypes.node,
  onEventChange: PropTypes.func,
  onSlotSelect: PropTypes.func,
};

SmallCalendar.defaultProps = {
  details: true,
  events: [],
};

export default SmallCalendar;
