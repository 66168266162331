import GlobalService from "./_GlobalService";

class HorseService extends GlobalService {

    constructor() {
        super("horses");
    }
    
};

export default new HorseService();


