import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { default as MUIButton } from "@material-ui/core/Button";
import PropTypes from "prop-types";

import "./style.css";

const sizes = {
  sm: {
    className: "app-button-small p-1 pl-2 pr-2 m-1",
  },
  md: {
    className: "p-2 pl-4 pr-4 m-2",
  },
};

function Button(props) {
  const { size, className, children, ...other } = props;
  return (
    <button className={`${className} app-button ${sizes[size].className}`} {...other}>
      {children}
    </button>
  );
}

Button.inputProps = {
  size: PropTypes.oneOf("sm", "md"),
  color: PropTypes.oneOf("primary", "secondary", "white"),
  className: PropTypes.any,
};

Button.defaultProps = {
  size: "md",
  color: "primary",
  className: "",
};

export default Button;
