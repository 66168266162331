export const equals = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const length = (object) => {
  if (!notNull(object)) return 0;
  if (Array.isArray(object)) return object.length;
  else return Object.keys(object).length;
};

export const notNull = (object) => {
  return object !== undefined && object !== null;
};

export const containsInvariant = (str, search) => {
  if (!str) return false;
  if (!search) return true;
  let strCropped = str.substring(0, search.length);
  return strCropped.localeCompare(search, undefined, { sensitivity: "base" }) === 0;
};

export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadFileBase64 = (file) => {
  const byteCharacters = atob(`${file.data}`);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([byteArray], { type: file.type });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = file.name;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};
