import React, { Component } from "react";
import ReactLoading from "react-loading";
import SplitPane from "../../components/SplitPane";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Notifier from "../../components/Notifier";
import { RoleService, UserService, PrestationTypeService, PrestationService, FileService } from "../../services/Services";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Tabs from "../../components/Tabs";
import Panel from "../../components/Panel";
import { InputText, InputSelect, InputSelectCheckbox, InputDate } from "../../components/Inputs";
import moment from "moment";

import FilePicker from "../../components/FilePicker";
import CreateUserDialog from "./CreateUserDialog";
import UserInformation from "./UserInformation";
import UserPrestations from "./UserPrestations";
import { length, equals, downloadFileBase64 } from "../../helpers/helpers";
import { createDate, dateToString, momentDateToString, stringDateToFormattedDate } from "../../helpers/date";
import { CONST_WIDTH_MOBILE } from "../../helpers/const";

export default class User extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      currentUser: false,
      currentUserModified: false,
      roles: [],
      prestations: [],
      currentRoleToAdd: {},

      genderOptions: ["H", "F"],
      popUpAddUserOpened: false,
      loading: true,
      viewList: true,
    };

    this.isMobile = window.innerWidth < CONST_WIDTH_MOBILE;
    this.refDetails = React.createRef();
    this.refList = React.createRef();

    this.onRowSelected = this.onRowSelected.bind(this);
    this.onUserCreated = this.onUserCreated.bind(this);
    this.onUserRoleChanged = this.onUserRoleChanged.bind(this);
    this.updateDataAsync = this.updateDataAsync.bind(this);
    this.updateDataAsyncForUser = this.updateDataAsyncForUser.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateDataAsync()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((e) => this.setState({ loading: false }));
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
        REFRESH DATA
    */
  updateDataAsync = () =>
    new Promise((resolve, reject) => {
      const promises = [UserService.getAllShort(), RoleService.getAll()];

      if (length(this.state.currentUser) > 0) {
        const userPromise = this.updateDataAsyncForUser(this.state.currentUser.id);
        promises.push(userPromise);
      }

      Promise.all(promises)
        .then((res) => {
          const users = res[0];
          const roles = res[1];
          if (this._isMounted) {
            this.setState({
              users,
              roles,
            });
          }
          resolve();
        })
        .catch((e) => {
          console.error(e);
          Notifier.showNotification("error", `Erreur lors de la récupération des données`);
          this.setState({ loading: false });
          reject(e);
        });

      PrestationService.getAllLastYear()
        .then((allPresta) => {
          var prestations =
            allPresta &&
            allPresta.map((presta) => {
              let start = moment(createDate(presta.start));
              let end = moment(createDate(presta.end));
              let dateString = "";

              if (!start.isValid() || !end.isValid()) {
                start = presta.start;
                end = presta.end;
                dateString = `de ${start} à ${end} `;
              } else {
                start = stringDateToFormattedDate(start);
                end = stringDateToFormattedDate(end);
                dateString = `du ${start} au ${end} `;
              }
              let comment = presta.comment ? ` (${presta.comment})` : "";
              return {
                ...presta,
                fullname: `${presta.template} ${comment}`,
              };
            });

          this.setState({
            prestations,
          });
        })
        .catch((e) => console.error(e));
    });

  updateDataAsyncForUser = async (user) =>
    new Promise((resolve, reject) => {
      if (user?.id) {
        UserService.find(user.id)
          .then((userFound) => {
            if (this._isMounted && length(userFound) > 0) {
              this.setState({
                currentUser: userFound,
                currentUserModified: userFound,
                loading: false,
              });
            }
            resolve();
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      }
    });

  /*
        POPUP
    */
  showAddItemPopUp = () => {
    this.setState({ popUpAddUserOpened: true });
  };

  /*
        MANAGE USER HIMSELF
    */
  onRowSelected = (rowId) => {
    const { currentUser, currentUserModified } = this.state;

    if (!equals(currentUser.id, rowId)) {
      // new user selected, check if modification are currently executed
      if (!equals(currentUser, currentUserModified) && length(currentUserModified) > 0) {
        Notifier.showNotification(
          "error",
          `Vous avez des modifications en attente, merci d'enregistrer ou annuler ces derniers pour changer d'utilisateurs`
        );
      } else {
        this.setState({ loading: true });
        this.updateDataAsyncForUser({id: rowId});
      }
    }

    this.setState({ viewList: false });
  };
  onUserCreated = async () => {
    console.log("onUserCreated");
    this.setState({
      popUpAddUserOpened: false,
      loading: true,
      newUser: {},
    });
    this.updateDataAsync().then(() => {
      console.log("onUserCreated updateDataAsync OK, settings loading to false");
      this.setState({ loading: false });
    });
  };
  onDeleteUser = () => {
    UserService.disable(this.state.currentUser.id)
      .then(() => {
        this.setState({
          currentUser: {},
          currentUserModified: {},
          newUser: {},
          viewList: true,
        });
        this.updateDataAsync();
        Notifier.showNotification("success", `L'utilisateur à bien été supprimé`);
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de la suppression`);
      });
  };
 
  /*
        ROLES
    */
  onUserRoleChanged = (newRoles) => {
    this.setState({ loading: true });
    UserService.updateRoles(
      this.state.currentUserModified.id,
      newRoles.filter((r) => r.checked)
    )
      .then(() => {
        this.updateDataAsyncForUser(this.state.currentUserModified);
        Notifier.showNotification("success", "Roles sauvegardés");
      })
      .catch((e) => {
        this.setState({ loading: false });
        console.error(e);
        Notifier.showNotification("error", "Impossible de sauver les roles");
      });
  };

  /*
        FILES
    */
  onUserFileLoaded = (file) =>
    new Promise((resolve, reject) => {
      file.userId = this.state.currentUser.id;
      console.log(file);
      FileService.create(file)
        .then((id) => {
          console.log(id);
          if (id > 0) {
            resolve(true);
            this.updateDataAsyncForUser(this.state.currentUserModified);
          } else {
            resolve(false);
          }
        })
        .catch((e) => reject(e));
    });
  onUserFileColumnEdit = (row) => {
    console.log(row);

    row = { ...row, visibleForClient: row.visibleForClient ? 1 : 0 };
    this.setState({
      currentUserModified: {
        ...this.state.currentUserModified,
        files: this.state.currentUserModified.files.map((file) => (file.id === row.id ? row : file)),
      },
    });
  };
  onUserFileDeleted = (file) =>
    new Promise((resolve, reject) => {
      FileService.delete(file.id)
        .then(() => {
          this.updateDataAsyncForUser(this.state.currentUserModified);
          Notifier.showNotification("success", `Le fichier à bien été supprimé`);
        })
        .catch((e) => {
          reject(e);
          Notifier.showNotification("error", `Erreur lors de la suppression`);
        });
    });

  onDownloadFile = (file) =>
    new Promise((resolve, reject) => {
      FileService.find(file.id)
        .then((file) => {
          downloadFileBase64(file);
        })
        .catch((e) => console.error(e));
    });

  render() {
    const { currentUser, users, currentUserModified, roles, popUpAddUserOpened, loading } = this.state;

    const rolesWithCheck =
      currentUser &&
      roles.map((role) => ({
        ...role,
        checked: currentUserModified.rolesUsers ? length(currentUserModified.rolesUsers.find((userRole) => userRole.roleId == role.id)) > 0 : false,
      }));

    return (
      <div className="h-100 w-100">
        {loading && (
          <div className="app-loading-spinner">
            <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
          </div>
        )}

        <SplitPane split="vertical" defaultSize={"35%"} className="p-2" style={{ height: "100%", overflow: "auto" }}>
          {(!this.isMobile || this.state.viewList) && (
            <div ref={this.refList} className="shadowed" style={{ backgroundColor: "#fff", height: "100%", marginRight: "4px" }}>
              <CreateUserDialog
                open={popUpAddUserOpened}
                handleUpdate={() => this.onUserCreated(true)}
                handleClose={() => this.setState({ popUpAddUserOpened: false })}
              />

              <Table
                columns={[
                  { title: "Nom", field: "lastName" },
                  { title: "Prénom", field: "firstName" },
                  { title: "Téléphone", field: "phone" },
                  { field: "nameToContact", hidden: true },
                  { field: "nameToContact2", hidden: true },
                ]}
                data={users}
                handleAdd={() => this.setState({ popUpAddUserOpened: true })}
                handleRowClick={this.onRowSelected}
                options={{
                  search: true,
                  noPadding: true,
                  affectRowColor: [
                    { column: "needPayment", style: { backgroundColor: "var(--color-warning-alpha)" } },
                    { column: "needPaymentUrgent", style: { backgroundColor: "var(--color-danger-alpha)" } },
                  ],
                  fixedHeight: true,
                }}
                style={{ minWidth: "100%" }}
              />
            </div>
          )}
          {(!this.isMobile || !this.state.viewList) && (
            <div ref={this.refDetails} className="d-flex flex-column h-100">
              {this.isMobile && <Button onClick={() => this.setState({ viewList: true })}>Retour</Button>}

              {currentUser && (
                <div style={{ marginLeft: "4px" }}>
                  <Tabs theme="rounded" split className="mt-xs-3">
                    <div label="Informations">
                      <UserInformation user={currentUser} handleUpdate={() => this.updateDataAsyncForUser(currentUser)} />
                    </div>

                    <div label="Prestations">
                      <UserPrestations
                        user={currentUser}
                        prestations={this.state.prestations}
                        handleUpdate={() => this.updateDataAsyncForUser(currentUser)}
                      />
                    </div>
                    <div label="Documents">
                      <FilePicker handleFileLoaded={this.onUserFileLoaded} />

                      <Panel label="Documents" fullHeight className="col-md-12 mt-3 flex-shrink-1 flex-grow-1">
                        <Table
                          columns={[
                            { title: "Visible", field: "visibleForClient", boolean: true, editable: true },
                            { title: "Nom", field: "name", editable: true },
                          ]}
                          data={currentUserModified.files}
                          className="small"
                          handleColumnEdit={this.onUserFileColumnEdit}
                          handleEdit={(file) => this.onDownloadFile(file)}
                          handleDelete={(file) => this.onUserFileDeleted(file)}
                          options={{
                            search: true,
                            noPadding: true,
                            selectedRowColor: "var(--gray)",
                            headerStyle: {
                              backgroundColor: "var(--gray)",
                            },
                          }}
                        />
                      </Panel>
                    </div>
                    {/*
                    <div label="Roles">
                      <InputSelectCheckbox data={rolesWithCheck} handleChange={this.onUserRoleChanged} />
                    </div>
                    <div label="Paramètres">
                      <Button
                        color="danger"
                        onClick={() => {
                          if (window.confirm(`Frère t'es sûr de vouloir supprimer ${this.state.currentUser.firstName}?`)) this.onDeleteUser();
                        }}
                      >
                        Supprimer utilisateur
                      </Button>
                    </div>
                      */}
                  </Tabs>
                </div>
              )}
            </div>
          )}
        </SplitPane>
      </div>
    );
  }
}
