import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        listStyle: "none",
        margin:"10px",
      //backgroundColor: theme.palette.primary.main,
      //color: theme.palette.primary.contrastText
    },
    splitted: {
        padding:"20px",
        margin:"10px",
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-around",
        backgroundColor:"#fff",
    },
    rectangle: {
        borderRadius:"0.25rem",
        boxShadow:"0 .46875rem 2.1875rem rgba(84,92,216,.03), \
            0 .9375rem 1.40625rem rgba(84,92,216,.03), \
            0 .25rem .53125rem rgba(84,92,216,.05), \
            0 .125rem .1875rem rgba(84,92,216,.03)",
        border: "2px solid var(--color-gray-light)"
    }
});

class ListItem extends Component {
    render() {
        const { children, theme = "splitted", className = "", ...other } = this.props;
       
        return (
            <li {...other} className={`col-md-12 ${this.props.classes.root} ${this.props.classes[theme]} ${className}`}>
                {children}
            </li>
        )
    }
}

ListItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default withStyles(styles)(ListItem);