import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./style.css";
import { FiUpload } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
import ReactLoading from "react-loading";

const FILES_TYPES =
  "\
    application/pdf, \
    application/vnd.openxmlformats-officedocument.wordprocessingml.document, \
    application/vnd.openxmlformats-officedocument.presentationml.presentation, \
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, \
    image/png, \
    image/jpeg";

const MAX_FILES_SIZE = 33554432; //33 Mo <=> 2^25
const DISPLAY_DURATION = 4000;

const FilePicker = (props) => {
  const [file, setFile] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length == 0) return;

    let tempFile = acceptedFiles[0];
    const reader = new FileReader();
    tempFile.loaded = false;
    tempFile.status = "warning";
    setFile(tempFile);

    reader.onabort = (e) => {
      setFile();
      console.error(e);
    };
    reader.onerror = (e) => {
      setFile();
      console.error(e);
    };
    reader.onloadstart = function (e) {
      console.log("onDrop onloadstart");
    };
    reader.onload = () => {
      console.log("onDrop onload");
      tempFile.result = reader.result;

      if (props.handleFileLoaded) {
        props
          .handleFileLoaded(tempFile)
          .then((success) => {
            console.log(success);
            if (success) {
              tempFile.loaded = true;
              tempFile.status = "success";
              setFile(tempFile);
            } else {
              tempFile.status = "danger";
              setFile(tempFile);
            }

            removeFileAsync();
          })
          .catch((e) => {
            console.error(e);
            tempFile.status = "danger";
            setFile(tempFile);
          });
      } else {
        tempFile.status = "success";
        setFile(tempFile);
      }
    };

    reader.readAsDataURL(tempFile);
  }, []);

  const removeFileAsync = async () => {
    setTimeout(() => {
      setFile();
    }, DISPLAY_DURATION);
  };

  const dropzone = useDropzone({
    onDrop,
    accept: FILES_TYPES,
    minSize: 0,
    maxSize: MAX_FILES_SIZE,
    multiple: false,
  });

  const { isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles } = dropzone;

  const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_FILES_SIZE;
  const isFileWrongType = rejectedFiles.length > 0 && !FILES_TYPES.split(",").includes(rejectedFiles[0].type);

  return (
    <div className="container text-center">
      <div className="app-dropzone" {...getRootProps()}>
        <FiUpload className="mr-3" style={{ width: "40px", height: "40px" }} />

        <input className="app-dropzone-input" {...getInputProps()} />
        <span>{!isDragActive && "Cliquer ou glisser un fichier"}</span>
        <span className="text-success">{isDragActive && !isDragReject && "Lacher dans la zone pour uploader"}</span>
        <span className="text-danger">{isDragReject && "Type de fichier pas accepté, désolé"}</span>

        {isFileTooLarge && <div className="alert-danger mt-2">Le fichier est trop volumineux</div>}
        {isFileWrongType && <div className="alert-danger mt-2">Type de fichier pas accepté, désolé</div>}

        {file !== undefined && file.name !== undefined ? (
          <div className={`app-dropzone-file d-flex flex-row alert-${file.status}`}>
            <div className="d-flex flex-row">
              {file.status === "danger" && <MdErrorOutline className="app-dropzone-file-status" />}
              {file.status === "success" && <IoMdCheckmarkCircleOutline className="app-dropzone-file-status" />}
              {file.status === "warning" && (
                <ReactLoading
                  className="app-dropzone-file-status"
                  type="spinningBubbles"
                  height="20px"
                  width="20px"
                  color={"#000"}
                />
              )}
              <h3>{file.name}</h3>
            </div>
            <span>Taille: {(Math.round((file.size / 1000000.0) * 10) / 10).toFixed(1)}Mo</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FilePicker;
