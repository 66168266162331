import Cookies from "js-cookie";
import { isLoggedIn, getToken } from "./auth";

export const customFetch = (method, uri, data = null, additionalRequestOptions = {}) =>
  new Promise((resolve, reject) => {
    const requestOptions = {
      method: method,
      //mode: 'no-cors',
      headers: {
        //'Access-Control-Allow-Origin': window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: ''),
        //Accept: "*/*",
        ...additionalRequestOptions.headers,
      },
      body: data ? JSON.stringify(data) : null,
      ...additionalRequestOptions,
    };

    if(!additionalRequestOptions.noContentType) {
      //requestOptions.headers["Content-Type"] = "application/json-patch+json";
      requestOptions.headers["Content-Type"] = "application/json; charset=utf-8";
    }

    if (isLoggedIn()) {
      requestOptions.credentials = 'include'
      requestOptions.headers.Authorization = "Bearer " + getToken();
    }

    fetch(uri, requestOptions)
      .then(async (res) => {
        if (res.ok) {
          var data = await res.text();
          if (!data) resolve({});
          var returnedData = data;
          try {
            returnedData = JSON.parse(data);
          } catch { }
          resolve(returnedData);
        } else {
          reject(await res.text());
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
