import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@material-ui/core";
import { InputText, InputSelect, InputDate } from "../../components/Inputs";
import { UserService, HorseService } from "../../services/Services";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "react-select";
import Button from "../../components/Button";
import ReactLoading from "react-loading";
import Notifier from "../../components/Notifier";
import { length, notNull } from "../../helpers/helpers";
import { CONST_WIDTH_MOBILE } from "../../helpers/const";
import CustomDialogTitle from "../../components/Dialog/dialog-title";

const isMobile = window.innerWidth < CONST_WIDTH_MOBILE;

export default function EditHorseDialog(props) {
  const { data, open, handleClose, handleUpdate, children } = props;

  const [mode, setMode] = React.useState();
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [horse, setHorse] = React.useState();
  const [opened, setOpened] = React.useState(false);
  const [users, setUsers] = React.useState();

  React.useEffect(() => {
    if (data && data.id) {
      setMode("edit");
      setHorse(data);
    } else {
      setMode("create");
      setHorse({});
    }

    UserService.getAll()
      .then((users) => {
        setUsers(users.map((user) => ({ ...user, value: user.id, label: user.name })));
      })
      .catch((e) => console.error(e));
    setOpened(open);
  }, [data, open]);

  const onInputChange = (event, value) => {
    setHorse({ ...horse, [event.target.name]: value });
  };

  const onUsersChange = (newUsers) => {
    let newUsersFound = [];
    if (length(newUsers) > 0) {
      newUsers.forEach((user) => {
        const userFound = users?.find((u) => u.id == user.value);
        if (notNull(userFound)) {
          newUsersFound.push({
            user: userFound,
            userId: user.value,
            horseId: horse.id,
          });
        }
      });
    }

    setHorse({
      ...horse,
      horseUser: newUsersFound,
    });
  };

  function addHorse() {
    setLoadingCreate(true);
    HorseService.create(horse)
      .then(() => {
        Notifier.showNotification("success", `Cheval ajouté`);
        handleClose();
        handleUpdate();
        setLoadingCreate(false);
      })
      .catch((e) => {
        console.error(e);
        Notifier.showNotification("error", `Erreur lors de l'ajout : ${e}`);
        setLoadingCreate(false);
      });
  }

  function editHorse() {
    setLoadingCreate(true);
    HorseService.update(horse)
      .then(() => {
        Notifier.showNotification("success", `Cheval sauvegardé`);
        handleClose();
        handleUpdate();
        setLoadingCreate(false);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de l'édition : ${e}`);
        console.error(e);
        setLoadingCreate(false);
      });
  }

  const horsesUsers =
    horse && length(horse.horseUser) > 0
      ? horse.horseUser.map((hu) => ({
          value: hu.userId,
          label: users?.find((u) => u.id == hu.userId)?.name,
        }))
      : [];

  return (
    <div style={{ position: "fixed", bottom: "15px", right: "15px", zIndex: 1 }}>
      <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened}>
        <CustomDialogTitle onClose={handleClose}>
          Édition cheval
        </CustomDialogTitle>
        <DialogContent className="d-flex flex-column" >
          {horse && (
            <>
              <InputText name="name" title="Nom du cheval" variant="outlined" value={horse.name} handleChange={onInputChange} />

              <InputText name="number" title="Identifiant" variant="outlined" className="mt-4" value={horse.number} handleChange={onInputChange} />
              <InputSelect
                name="gender"
                className="mt-4"
                title="Genre"
                value={horse.gender}
                values={[
                  { title: "Jument", value: "F" },
                  { title: "Cheval", value: "H" },
                ]}
                handleChange={onInputChange}
              />
              <InputSelect
                name="type"
                className="mt-4"
                title="Type"
                value={horse.type}
                values={[
                  { title: "Demi-pension", value: "dp" },
                  { title: "Pension", value: "pension" },
                  { title: "Proprietaire", value: "proprio" },
                  { title: "Au pair", value: "at-pair" },
                ]}
                handleChange={onInputChange}
              />

              <InputText name="comment" title="Commentaire" className="mt-4" multiline rows="3" value={horse.comment} handleChange={onInputChange} />

              <div className="mt-4 position-relative">
                <InputDate name="birthday" label="Date de naissance" value={horse.birthday} handleChange={onInputChange} />
              </div>

              {/*
                               <Autocomplete
                                id="app-select-horse-user"
                                className="mt-4"
                                options={users}
                                getOptionLabel={(user) => user.name}
                                getOptionSelected={(option, value) => value && value.some(v => v.userId == option.id)}
                                value={horse.horseUser}
                                onChange={(event, newUser) => {
                                    console.log(newUser, horse.horseUser);
                                  setHorse({...horse, horseUser: [...horse.horseUser, {userId: newUser.id}]});
                                }}
                                renderInput={(params) => <TextField {...params} label="Propriétaire" variant="outlined" />}
                            />
                              */}

              <Select
                id="app-select-horse-user"
                value={horsesUsers}
                isMulti
                name="users"
                options={users}
                onChange={onUsersChange}
                className="mt-4 basic-multi-select"
                classNamePrefix="select"
              />

              <Button
                disabled={loadingCreate}
                className=""
                type="submit"
                onClick={() => (mode === "edit" ? editHorse() : addHorse())}
                color="primary"
              >
                {loadingCreate ? (
                  <ReactLoading type="bubbles" style={{ margin: "auto", height: "26px", width: "26px" }} />
                ) : (
                  <span>{mode === "edit" ? "Editer" : "Ajouter"}</span>
                )}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
