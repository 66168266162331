import moment from "moment";

export const createDate = (date) => {
  if (typeof date != "string") {
    return null;
  }

  if (date) {
    let dateTime = date.split(" ");
    let parsedDate = dateTime[0].split("-").concat(dateTime[1]?.split(":"));
    var newDate = new Date();
    newDate.setDate(parsedDate[2]);
    newDate.setMonth(parsedDate[1] - 1);
    newDate.setFullYear(parsedDate[0]);
    if (parsedDate.length > 4) {
      newDate.setHours(parsedDate[3]);
      newDate.setMinutes(parsedDate[4]);
    } else {
      newDate.setHours(0);
      newDate.setMinutes(0);
    }
  } else {
    return new Date();
  }

  return newDate;
};

export const momentDateToString = (date) => {
  if (!date) return null;
  return date.format("YYYY-MM-DD HH:mm");
};

export const getMomentDate = (date) => {
  if (!date) return null;
  return moment(date, "YYYY-MM-DD HH:mm");
};

export const stringDateToFormattedDate = (strDate, displayTime = true) => {
  if (!strDate) return "";
  let format = `DD/MM/YYYY${(displayTime ? " HH:mm" : "")}`
  return moment(strDate).format(format);
}

export const areSame = (d1, d2) => {
  return moment(d1).isSame(d2);
};

export const jsDateToString = (jsDate) => {
  var dd = jsDate.getDate();
  if (parseInt(dd) < 10) dd = "0" + dd;
  var mm = jsDate.getMonth() + 1;
  if (parseInt(mm) < 10) mm = "0" + mm;
  var yyyy = jsDate.getFullYear();
  var h = jsDate.getHours();
  if (parseInt(h) < 10) h = "0" + h;
  var m = jsDate.getMinutes();
  if (parseInt(m) < 10) m = "0" + m;
  return `${yyyy}-${mm}-${dd} ${h}:${m}`;
};
