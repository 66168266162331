import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class Panel extends Component {

    render() {
        const { className, label, children, fullHeight, ...other} = this.props;

        return (
            <div className={`app-panel ${className} w-100`} {...other}>
                <label className="app-panel-label">{label}</label>
                <div className={`app-panel-body ${fullHeight ? "h-100":""}`}>
                    {children}
                </div>
            </div>
        );
    }
}

Panel.propTypes = {
    label: PropTypes.string
};

export default Panel;