import React, { Component } from 'react';  
import { Card as BSCard, CardTitle, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import './style.css';

export default class Card extends Component {

    static propTypes = {
        title: PropTypes.string,
        className: PropTypes.string,
        nopadding: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    };

    render() {
        const { title, children, className, nopadding, ...other } = this.props;
        const padding = nopadding?" nopadding ":"";
        const classNameProps = className?className:"";
        const appClass = "app-card border-primary "+classNameProps+padding;

        return (
        <BSCard {...other} className={appClass} >
            {
                title && (
                    <CardTitle className="mt-2 text-center color-primary">{title}</CardTitle>
                )
            }
            <CardBody>
                {children}
            </CardBody>
        </BSCard>
        )
    }
}