import Axios from "axios";
import { customFetch } from "../helpers/fetch";
import { CONST_URI_API } from "../helpers/const";

class GlobalService {
  baseUrl = "";

  constructor(apiUrl) {
    this.baseUrl = `${CONST_URI_API}${apiUrl}`;
  }

  refresh = () =>
    new Promise((resolve, reject) => {
      customFetch("GET", this.baseUrl)
        .then((response) => {
          this.collection = response.data;
          resolve();
        })
        .catch((e) => reject(e));
    });

  getAll = () =>
    new Promise((resolve, reject) => {
      customFetch("GET", this.baseUrl).then((data) => {
        resolve(data);
      });
    });

  find = (id) =>
    new Promise((resolve, reject) => {
      customFetch("GET", `${this.baseUrl}/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });

  update = (item) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/${item.id}`, item)
        .then(() => resolve())
        .catch((e) => reject(e));
    });

  create = (item) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}`, item)
        .then((data) => resolve(data))
        .catch((e) => reject(e));
    });

  delete = (id) =>
    new Promise((resolve, reject) => {
      if (window.confirm("Confirmer la suppression")) {
        customFetch("DELETE", `${this.baseUrl}/${id}`)
          .then((data) => resolve(data))
          .catch((e) => reject(e));
      }
    });
}

export default GlobalService;
