import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@material-ui/core";
import { InputText, InputSelect, InputDate } from "../../components/Inputs";
import Select from "react-select";
import Button from "../../components/Button";
import ReactLoading from "react-loading";
import Notifier from "../../components/Notifier";
import { length, notNull } from "../../helpers/helpers";
import { CONST_WIDTH_MOBILE, PrestationTypes } from "../../helpers/const";
import { PrestationUserService } from "../../services/Services";
import Row from "reactstrap/lib/Row";
import { FaTrash } from "react-icons/fa";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import SearchIcon from "@material-ui/icons/Search";
import CustomDialogTitle from "../../components/Dialog/dialog-title";
import SmallCalendar from "../../components/Calendar/SmallCalendar";
import moment from "moment";
import { momentDateToString } from "../../helpers/date";

const isMobile = window.innerWidth < CONST_WIDTH_MOBILE;

export default function EditUserPrestationDialog(props) {
  const { data, open, handleClose, handleUpdate, handleOpenPrestation, children } = props;

  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [userPrestation, setUserPrestation] = React.useState();
  const [newEvent, setNewEvent] = React.useState();
  const [newEventTime, setNewEventTime] = React.useState();
  const [opened, setOpened] = React.useState(false);
  const [openedTime, setOpenedTime] = React.useState(false);

  React.useEffect(() => {
    setOpened(open);
    setUserPrestation(data);
  }, [data, open]);

  const onInputChange = (event, value) => {
    setUserPrestation({ ...userPrestation, [event.target.name]: value });
  };

  const onCancel = () => {
    handleClose();
  };

  function onEdit() {
    setLoadingCreate(true);
    PrestationUserService.update(userPrestation)
      .then(() => {
        Notifier.showNotification("success", `Informations sauvegardées`);
        handleClose();
        handleUpdate();
        setLoadingCreate(false);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de la sauvegarde`);
        console.error(e);
        setLoadingCreate(false);
      });
  }

  function onDelete() {
    setLoadingCreate(true);
    PrestationUserService.delete(userPrestation.id)
      .then(() => {
        Notifier.showNotification("success", `Utilisateur enlevé de cette prestation`);
        handleClose();
        handleUpdate();
        setLoadingCreate(false);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors de la suppression`);
        console.error(e);
        setLoadingCreate(false);
      });
  }

  const getFormattedEvents = () => {
    return (
      userPrestation.datePrestationUser &&
      userPrestation.datePrestationUser.map((date) => ({
        start: moment(date).startOf("day"),
        end: moment(date).endOf("day"),
        allDay: true,
        title: " ",
      }))
    );
  };

  const onEventChange = (events) => {
    if (events.length > userPrestation.datePrestationUser.length) setOpenedTime(true);
    else {
      setUserPrestation({ ...userPrestation, datePrestationUser: events.map((e) => momentDateToString(e.start)) });
    }
  };

  const onSlotSelect = (event) => {
    setNewEvent(momentDateToString(event));
  };

  const setUserPrestationHour = () => {
    let eventDate = newEvent.split(" ")[0];
    let newEventDate = `${eventDate} ${newEventTime}`;
    setUserPrestation({ ...userPrestation, datePrestationUser: [...userPrestation.datePrestationUser, newEventDate] });
    setOpenedTime(false);
    setNewEventTime("");
  };

  return (
    <div style={{ position: "fixed", bottom: "15px", right: "15px", zIndex: 1 }}>
      <div style={{ zIndex: 2 }}>
        <Dialog maxWidth={false} onClose={() => setOpenedTime(false)} aria-labelledby="customized-dialog-title" open={openedTime}>
          <DialogContent className="d-flex p-5">
            <InputText
              name="timeStart"
              style={{ width: 200 }}
              label="Heure du cours"
              defaultValue="17:30"
              type="time"
              handleChange={(e, value) => setNewEventTime(value)}
            ></InputText>

            <Button disabled={!newEventTime} size="sm" type="submit" onClick={() => setUserPrestationHour()} color="primary">
              OK
            </Button>
          </DialogContent>
        </Dialog>
      </div>
      <Dialog maxWidth={false} onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened}>
        <CustomDialogTitle onClose={handleClose}>Édition prestation d'un utilisateur</CustomDialogTitle>
        <DialogContent className="d-flex flex-column">
          {userPrestation && (
            <>
              <InputText
                style={{ width: "300px" }}
                name="comment"
                title="Commentaire"
                multiline
                rows="3"
                value={userPrestation.comment}
                handleChange={onInputChange}
              />
              <InputText
                className="mt-2"
                name="price"
                title="Prix"
                type="price"
                value={userPrestation.price}
                style={{ width: "85px" }}
                handleChange={onInputChange}
              />

              {userPrestation.type == PrestationTypes.DATES_FOR_EACH && (
                <div className="clearfix mt-1">
                  <SmallCalendar events={getFormattedEvents()} onEventChange={onEventChange} onSlotSelect={onSlotSelect} />
                </div>
              )}

              <Row className="mt-2">
                {loadingCreate ? (
                  <ReactLoading type="bubbles" style={{ margin: "auto", height: "26px", width: "26px" }} />
                ) : (
                  <>
                    <Button disabled={loadingCreate} size="sm" type="submit" onClick={() => onEdit()} color="primary">
                      <SaveOutlinedIcon /> Sauvegarder
                    </Button>
                    <Button onClick={onDelete} size="sm" color="danger">
                      <FaTrash /> SUPPRIMER
                    </Button>
                    <Button onClick={handleOpenPrestation} size="sm" color="white">
                      <SearchIcon /> VOIR LA PRESTATION
                    </Button>
                  </>
                )}
              </Row>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
