import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Tab extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        handleClick: PropTypes.func.isRequired,
    };
  
    onClick = () => {
        const { label, handleClick } = this.props;
        handleClick(label);
    }
  
    render() {
        const { props: { handleClick, activeTab, label, className = "", ...other } } = this;
  
        let classes = `tab-list-item ${className}`;
  
        if (activeTab === label) {
            classes += ' tab-list-active';
        }
  
        return (
            <li className={classes}
                onClick={this.onClick} 
                {...other} >
            {label}
            </li>
        );
    }
}