import React, { Component } from "react";
import ReactLoading from "react-loading";
import UserService from "../../services/UserService";
import SplitPane from "../../components/SplitPane";
import Notifier from "../../components/Notifier";
import Card from "../../components/Card";
import Button from "../../components/Button";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { length, equals } from "../../helpers/helpers";
import { PrestationService } from "../../services/Services";
import { EditPrestationPanel, CreatePrestation } from "../Prestation";
import { Calendar as RBCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style.css";
import { ConditionalScrollBar, Scrollbar } from "../../components/Scrollbar";
import { CONST_CALENDAR_MESSAGES, CONST_WIDTH_MOBILE, PrestationTypes } from "../../helpers/const";
import { createDate, jsDateToString } from "../../helpers/date";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(RBCalendar);

const messages = {
  // new
  allDay: "Jour",
  previous: "<",
  next: ">",
  today: "Aujourd'hui",
  month: "Mois",
  week: "Semaine",
  day: "Jour",
  agenda: "Agenda",
  date: "Date",
  time: "Temps",
  event: "Évènement",
};

export default class Calendar extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      height: 0,
      users: [],
      currentDate: new Date(),
      currentDateEvents: [],
      currentEvent: {},
      currentEventModified: {},
      events: [],
    };
    this.refDetails = React.createRef();
    this.isMobile = window.innerWidth < CONST_WIDTH_MOBILE;
    this.onCurrentEventChange = this.onCurrentEventChange.bind(this);
    this.onEventCancel = this.onEventCancel.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: event.color,
        opacity: 0.8,
        display: "block",
      },
    };
  };

  formatPrestationsToEvents = (prestations) => {
    var formattedArray = [];
    prestations.forEach((presta) => {
      var dates = [];
      switch (presta.type) {
        case PrestationTypes.DATES_FOR_ALL:
          dates.push({
            ...presta,
            start: createDate(presta.start),
            end: createDate(presta.end),
            title: presta.template,
          });
          break;

        case PrestationTypes.DATES_FOR_EACH:
          if (presta.prestationUser.length > 0) {
            presta.prestationUser.forEach((pu) => {
              dates = dates.concat(
                pu.datePrestationUser.map((dpu) => {
                  var date = createDate(dpu);
                  let timeStart = dpu.split(" ")[1];
                 
                  let end = date;
                  end.setHours(end.getHours() + 1);
                  let timeEnd = jsDateToString(end).split(" ")[1];

                  return {
                    ...presta,
                    start: date,
                    end: end,
                    timeStart: timeStart,
                    timeEnd: timeEnd,
                    title: pu.user.name,
                  };
                })
              );
            });
          }
          break;

        case PrestationTypes.DATES_RECURRING_FOR_ALL:
          dates = presta.datePrestation.map((dp) => {
            let date = dp.split(" ")[0];
            let start = createDate(date + " " + presta.timeStart);
            let end = createDate(date + " " + presta.timeEnd);
            return {
              ...presta,
              start: start,
              end: end,
              title: presta.comment,
            };
          });

          break;
      }

      formattedArray = formattedArray.concat(dates);
    });

    return formattedArray;
  };

  componentDidMount() {
    const height = this.divElement.clientHeight - 40;
    this.setState({ height });
    this._isMounted = true;

    UserService.getAllMinimized()
      .then((users) => {
        if (this._isMounted) {
          this.setState({ users });
        }
      })
      .catch((e) => console.error(e));

    this.refresh().then(() => {
      this.onSlotSelect(this.state.currentDate);
    });
  }

  refresh = (forceRefresh = false) =>
    new Promise((resolve, reject) => {
      this.setState({ loading: true });
      PrestationService.getAllForCalendar(forceRefresh)
        .then((items) => {
          console.log(items);
          const formattedArray = this.formatPrestationsToEvents(items);

          console.log(formattedArray);

          if (this._isMounted) {
            this.setState({ events: formattedArray, loading: false });
          }
          resolve();
        })
        .catch((e) => {
          console.error(e);
          this.setState({ loading: false });
          Notifier.showNotification("error", `Erreur lors du chargement des données`);
          reject();
        });
    });

  componentWillUnmount() {
    this._isMounted = false;
  }

  onEventResizeOrMove = (e) => {
    if (moment(e.event.start).isSame(e.start) && moment(e.event.end).isSame(e.end)) return;

    const eventToUpdate = {
      ...e.event,
      start: e.start,
      end: e.end,
    };

    PrestationService.update(eventToUpdate)
      .then(() => {
        this.refresh();
        Notifier.showNotification("success", `Cours mis à jour`);
      })
      .catch((e) => {
        Notifier.showNotification("error", `Erreur lors du déplacement : ${e}`);
        console.error(e);
      });
  };

  onEventSelect = (eventSelected) => {
    if (length(this.state.currentEvent) > 0 && !equals(this.state.currentEvent, this.state.currentEventModified)) {
      Notifier.showNotification("error", `Sauvegarder ou annuler les modifications en cours avant de changer de cours`);
    } else {
      PrestationService.find(eventSelected.id).then((data) => {
        let currentDateEvents = this.state.events.filter((event) => {
          return (
            moment(eventSelected.start).isBetween(moment(event.start), moment(event.end)) ||
            moment(eventSelected.start).isSame(moment(event.start), "day") ||
            moment(eventSelected.start).isSame(moment(event.end), "day")
          );
        });
        currentDateEvents.sort((event1, event2) => {
          return moment(event1.start).isAfter(event2.start) ? 1 : -1;
        });

        this.setState({
          currentDateEvents: currentDateEvents,
          currentEvent: data,
          currentEventModified: data,
        });
      });
    }
    if (this.isMobile) this.scrollToDetails();
  };

  onEventCancel() {
    this.setState({ currentEventModified: this.state.currentEvent });
  }

  onSlotSelect = (options) => {
    let start = options ? options.start : this.state.currentDate;
    let currentDateEvents = this.state.events.filter((event) => {
      return (
        moment(start).isBetween(moment(event.start), moment(event.end)) ||
        moment(start).isSame(moment(event.start), "day") ||
        moment(start).isSame(moment(event.end), "day")
      );
    });
    currentDateEvents.sort((event1, event2) => {
      return moment(event1.start).isAfter(event2.start) ? 1 : -1;
    });

    this.setState({
      currentDate: start,
      currentDateEvents: currentDateEvents,
      currentEvent: null,
      currentEventModified: null,
    });
  };

  onCurrentEventChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState((prevState) => ({
      currentEventModified: {
        ...prevState.currentEventModified,
        [name]: value,
      },
    }));
  }

  onCurrentEventUsersChange = (users) => {
    this.setState({
      currentEventModified: {
        ...this.state.currentEventModified,
        users: users && users.length > 0 ? users.map((user) => this.state.users.find((u) => u.id === user.value)) : [],
      },
    });
  };

  scrollToDetails = () => this.refDetails.current.scrollIntoView();

  render() {
    const currentDate = moment(this.state.currentDate);

    const { currentEventModified, currentEvent, loading } = this.state;

    return (
      <>
        {loading && (
          <div className="app-loading-spinner">
            <ReactLoading type="spinningBubbles" height="20vh" width="20vh" />
          </div>
        )}

        <CreatePrestation handleCreate={() => this.refresh(true)} />
        <SplitPane split="vertical" minSize={50} defaultSize={"65%"} style={{ height: "100%", overflow: "auto" }}>
          <div className="h-100 p-2">
            <div
              className="app-calendar"
              ref={(divElement) => {
                this.divElement = divElement;
              }}
            >
              <DnDCalendar
                defaultDate={new Date()}
                defaultView="month"
                culture="fr"
                messages={CONST_CALENDAR_MESSAGES}
                localizer={localizer}
                events={this.state.events}
                onEventDrop={this.onEventResizeOrMove}
                onEventResize={this.onEventResizeOrMove}
                onSelectEvent={(event) => this.onEventSelect(event)}
                onSelectSlot={this.onSlotSelect}
                resizable
                selectable
                min={new Date(0, 0, 0, 6, 0, 0)}
                max={new Date(0, 0, 0, 22, 0, 0)}
                eventPropGetter={this.eventStyleGetter}
              />
            </div>
          </div>

          <div className={"p-2 d-flex flex-column " + (this.isMobile ? "" : "h-100")}>
            <Card style={{ height: this.isMobile ? "auto" : "40%", maxHeight: "50vh" }}>
              <ConditionalScrollBar show={!this.isMobile}>
                <h3>{currentDate.format("DD MMMM YYYY")}</h3>
                {this.state.currentDateEvents &&
                  this.state.currentDateEvents.length > 0 &&
                  this.state.currentDateEvents.map((event) => {
                    return (
                      <div key={event.id} className="calendar-course-item">
                        <a href="#" className="d-inline-flex" onClick={() => this.onEventSelect(event)}>
                          <span className="small m-auto">
                            De {event.timeStart} à {event.timeEnd}
                          </span>
                          <span className="ml-3">{event.template}</span>
                        </a>
                        <div className="ml-5">
                          {event.prestationUser &&
                            event.prestationUser.length > 0 &&
                            event.prestationUser.map((pu) => {
                              return <span key={pu.user.id}>{` ${pu.user.name},`}</span>;
                            })}
                        </div>
                      </div>
                    );
                  })}
              </ConditionalScrollBar>
            </Card>

            <div ref={this.refDetails} className="mt-2 d-flex" style={{ height: this.isMobile ? "" : "50%" }}>
              {length(currentEventModified) > 0 ? (
                <>
                  {!equals(currentEvent, currentEventModified) && (
                    <div className="d-inline-flex">
                      <Button size="sm" type="submit" onClick={this.onEventUpdate} color="primary">
                        <SaveOutlinedIcon />
                      </Button>
                      <Button size="sm" onClick={this.onEventCancel} color="danger">
                        <CancelOutlinedIcon />
                      </Button>
                    </div>
                  )}

                  <Card nopadding className="p-2 mt-1 flex-grow-1">
                    <EditPrestationPanel fixed={!this.isMobile} data={currentEventModified} handleChange={this.refresh} />
                  </Card>
                </>
              ) : null}
            </div>
          </div>
        </SplitPane>
      </>
    );
  }
}
