import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { InputText } from "../../components/Inputs";
import { UserService } from "../../services/Services";
import Button from "../../components/Button";
import ReactLoading from "react-loading";
import Notifier from "../../components/Notifier";
import CustomDialogTitle from "../../components/Dialog/dialog-title";

export default function CreateUserDialog({ open, handleClose, handleUpdate }) {
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [item, setItem] = React.useState({});
  const [opened, setOpened] = React.useState(open);

  React.useEffect(() => {
    setOpened(open);
    setItem({});
  }, [open]);

  const onInputChange = (event, value) => {
    setItem({ ...item, [event.target.name]: value });
  };

  function onCreateItem() {
    setLoadingCreate(true);

    console.log("creating item");
    UserService.create(item)
      .then(() => {
        console.log("create ok", handleUpdate);
        setLoadingCreate(false);
        Notifier.showNotification("success", `"${item.firstname}" à bien été ajouté`);
        handleUpdate();
      })
      .catch((e) => {
        setLoadingCreate(false);
        Notifier.showNotification("error", `Erreur lors de l'ajout`);
        console.error(e);
      });
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={opened} >
      <CustomDialogTitle onClose={handleClose}>
        Ajout d'un utilisateur
      </CustomDialogTitle>
      <DialogContent dividers className="d-flex flex-column p-3" >
        {item && (
          <>
            <InputText name="lastname" title="Nom" value={item.lastname} variant="standard" className="mb-4" handleChange={onInputChange} />
            <InputText name="firstname" title="Prénom" value={item.firstname} variant="standard" className="mb-4" handleChange={onInputChange} />
            <InputText name="email" title="Email" value={item.email} variant="standard" className="mb-4" handleChange={onInputChange} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus {...{ disabled: loadingCreate }} onClick={onCreateItem} color="primary">
          {loadingCreate ? <ReactLoading type="bubbles" height={26} width={26} /> : <span>Ajouter</span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
