import React, { Component } from 'react';  
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

class InputSelectCheckbox extends Component {

    constructor(props) {  
        super(props);  
        this.state = {
            data: props.data
        }
    }

    onChange =  id => event => {
        this.setState({
            data: this.state.data.map(item => {
                if(item.id === id) {
                    item.checked = event.target.checked;
                }
                return item;
            })
        });
        if(this.props.handleChange !== undefined)
            this.props.handleChange(this.state.data);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.data) {
            this.setState({data: this.state.data});
        }
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.data !== prevState.data){
            return {data : nextProps.data};
        }
        else return null;
    }

    render() {
        const { state: { data }, props: { handleChange, ...other }} = this;
        return (
        <FormGroup>
        {
            data.map(item => {
                const checked = item.checked || item.checked === 1 ? true : false;

                return (
                    <FormControlLabel
                        key={item.id}
                        control={<Checkbox color="primary" checked={checked} onChange={this.onChange(item.id)} value={item.id} />}
                        label={item.name}
                        {...other}
                    />
                )
            })
        }
        </FormGroup>
        );
    }
}

InputSelectCheckbox.propTypes = {
    data: PropTypes.array.isRequired,
    handleChange: PropTypes.func
};

export default InputSelectCheckbox;