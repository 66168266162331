import GlobalService from "./_GlobalService";

class CoffeeTransactionService extends GlobalService {

    constructor() {
        super("coffeetransactions");
    }
    

};

export default new CoffeeTransactionService();