import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { PhoneMask } from "./input-masks";

function InputText(props) {
  const { value = "", name, title, type, variant, handleChange, ...other } = props;

  const [currValue, setCurrValue] = useState("");

  // force to recall when props change
  useEffect(() => {
    if (value === null) {
      setCurrValue("");
    } else {
      setCurrValue(value);
    }
  }, [value]);

  const onChange = (event) => {
    const target = event.target;
    var value;
    switch (type) {
      case "checkbox":
        value = target.checked;
        break;
      case "number":
        value = parseInt(target.value);
        break;
      case "price":
        value = target.value.replace(",", ".");
        break;
      default:
        value = target.value;
    }

    setCurrValue(value);
    if (handleChange !== undefined) handleChange(event, value);
  };

  var customProps = {};
  if (type === "phone") {
    customProps.InputProps = { inputComponent: PhoneMask };
  } else if (type === "number") {
    // customProps.InputProps={inputComponent: NumberMask};
    customProps.type = type;
  } else if (type === "price") {
    customProps.type = type;
    customProps.InputProps = { endAdornment: <InputAdornment position="end">€</InputAdornment> };
  } else if (type !== undefined) {
    customProps.type = type;
  }

  return variant && variant === "wysiwyg" ? (
    <Editor
      init={{
        height: 400,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        quickbars_selection_toolbar:
          "bold italic underline strikethrough | fontselect fontsizeselect formatselect | forecolor backcolor | quicklink quickimage quicktable",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | forecolor backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist checklist outdent indent | table link image | removeformat preview help",
      }}
      onEditorChange={(content, editor) => handleChange(editor, content)}
      {...customProps}
      {...other}
    />
  ) : (
    <TextField
      id={name}
      name={name}
      value={currValue}
      label={title}
      variant={variant ? variant : "outlined"}
      size="small"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="off"
      onChange={onChange}
      {...customProps}
      {...other}
    />
  );
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  variant: PropTypes.oneOf(["outlined", "filled", "standard", "wysiwyg"]),
  type: PropTypes.oneOf(["text", "price", "number", "wysiwyg"]),
};

export default InputText;
