import React, { Component } from 'react';  
import { IconButton } from '@material-ui/core';

import './style.css';

export const Topbar = ({
    children,
    className = "",
    ...other
}) => {
    return (
        <div className={`app-topbar d-inline-flex ${className}`} color="light" expand="sm" {...other}>
            {children}
        </div>
    );
}

export const TopbarGroup = ({
    title,
    children
}) => {
    return (
        <div className="app-topbar-group">
            <div className="app-topbar-group-title">
                {title?title: (<div style={{height: '24px'}}></div>)}
            </div>
            <div className="app-topbar-group-children">
                {children}
            </div>
        </div>
    );
}

export class TopbarItemIcon extends Component{

   

    render() {
        return (
            <IconButton className="app-topbar-item-icon" to={this.props.to} >
                {this.props.children}
            </IconButton>
        );
    }
}

