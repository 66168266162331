import Axios from "axios";
import GlobalService from "./_GlobalService";
import UserService from "./UserService";
import { customFetch } from "../helpers/fetch";

class PrestationUserService extends GlobalService {
  constructor() {
    super("prestationsusers");
  }

  addUser = (req) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/adduser`, req)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });

  delete = (id) =>
    new Promise((resolve, reject) => {
      customFetch("DELETE", `${this.baseUrl}?id=${id}`)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });

  setPayed = (row) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/setpayed`, row)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });

  update = (pu) =>
    new Promise((resolve, reject) => {
      customFetch("POST", `${this.baseUrl}/`, pu)
        .then(() => {
          resolve();
        })
        .catch((e) => reject(e));
    });
}

export default new PrestationUserService();
