import React, { Component } from 'react';  
import {default as BSSplitPane } from 'react-split-pane';
import { CONST_WIDTH_MOBILE } from '../../helpers/const';
import './style.css';

export default class SplitPane extends Component {

    render() {
        const { style, children, ...other } = this.props;

        return window.innerWidth > CONST_WIDTH_MOBILE ? (
        <BSSplitPane {...other} style={{position:'relative', ...style}}>
            {children}
        </BSSplitPane> 
        ) : (
            <div {...other} style={{width:"100vw", position:'relative', ...style}}>
                {children}
            </div>
        );
    }
}