import React, { Component } from 'react'
import { isLoggedIn, handleLogin } from '../../helpers/auth'
import { useHistory, Redirect } from 'react-router-dom'
import Button from '../../components/Button';
import Notifier from '../../components/Notifier';
import ReactLoading from "react-loading";

export default function Login () {
  const history = useHistory();

  const [credentials, setCredentials] = React.useState({username: "", password:""})
  const [isLogin, setIsLogin] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  if (isLoggedIn()) return <Redirect to="/" />;


  const handleChange = event => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (!isLogin) {
      setIsLogin(true)
      handleLogin(credentials)
        .then(() => {
          setIsLogin(false)
          console.log("navigate to profile")

          history.push(`/`)
        })
        .catch(error => {
          Notifier.showNotification("error", "Impossible de se connecter")
          setIsLogin(false)
        })
    }
  }

  return (
    <div>
      <form method="post" onSubmit={e => handleSubmit(e)}>
          <h1 className="h3 mb-3 font-weight-normal">Connexion</h1>
          <div>
            <input
              id="username"
              type="text"
              name="username"
              placeholder="Username"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              id="password"
              type="password"
              name="password"
              placeholder="Mot de passe"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          {isLogin ? (
            <Button disabled className="mt-5" style={{ width: 184 }}>
              <ReactLoading type="bubbles" style={{display:"block", margin:"auto", height:"26px", width:"26px"}} />
            </Button>
          ) : (
            <Button type="submit" className="mt-5">
              Connexion
            </Button>
          )}
        </form>
    </div>
  )
}
