import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.css';

class Label extends Component {


    render() {
        const { label, color, children, ...other } = this.props;
        if(label) {
            return (
                <div className="d-flex flex-column" {...other}>
                    <p className="app-label-label">{label}</p>
                    <span className="app-label-value" style={{color: `var(--${color})`}}>{children}</span>
                </div>
            )
        } else {
            return (
                <span {...other}>{children}</span>
            )
        }
    }
}

Label.propTypes = {
    label: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    color: PropTypes.string
};

export default Label;